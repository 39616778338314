import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

import { ApiService } from 'src/app/services/api/api.service';
import { SignupService } from 'src/app/services/signup/signup.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import Swal from 'sweetalert2';
import { FundService } from 'src/app/services/fund/fund.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { Router } from '@angular/router';
import Utils from 'src/app/helpers/utils';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-dashboard-organization',
  templateUrl: './dashboard-organization.component.html',
  styleUrls: ['./dashboard-organization.component.scss'],
})
export class DashboardOrganizationComponent implements OnInit {
  @ViewChild('form') form: ElementRef<HTMLDivElement> | undefined;

  permissionObj: PermissionValues = {
    canAdd: false,
    canChange: false,
  };

  loading = true;

  today = Utils.todayString();

  funds: any[] = [];
  count: number = 0;
  offset: number = 0;

  anbimaChoices: SelectItem[] = [];

  applicableRole = 'ADM';

  filtered = false;
  dataFilters: any = null;

  perPageValue = 50;

  selectItems: SelectItem[] = [
    { label: '20 por página', value: '2' },
    { label: '30 por página', value: '3' },
    { label: '50 por página', value: '5' },
  ];

  perPageForm = this.formBuilder.group({
    perPage: ['20', [Validators.required]],
  });

  filterForm = this.formBuilder.group({
    search: [null],
    role: [null],
    created_from: [
      null,
      [
        Utils.validateWhenNotEmpty(Utils.validateMaxMinToday),
        Utils.validateWhenNotEmpty(Utils.validateMinDate),
      ],
    ],
    created_to: [
      null,
      [
        Utils.validateWhenNotEmpty(Utils.validateMaxMinToday),
        Utils.validateWhenNotEmpty(Utils.validateMinDate),
      ],
    ],
    updated_from: [
      null,
      [
        Utils.validateWhenNotEmpty(Utils.validateMaxMinToday),
        Utils.validateWhenNotEmpty(Utils.validateMinDate),
      ],
    ],
    updated_to: [
      null,
      [
        Utils.validateWhenNotEmpty(Utils.validateMaxMinToday),
        Utils.validateWhenNotEmpty(Utils.validateMinDate),
      ],
    ],
  });

  showFilterModal = false;

  constructor(
    private formBuilder: FormBuilder,
    private api: ApiService,
    public signupService: SignupService,
    private authService: AuthService,
    private fundService: FundService,
    private toast: ToastService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.authService.permissionsLoadedEvent.subscribe(() => {
      this.authService.canViewPage(['de_can_view_fund']);
    });

    if (this.authService.user.active_register) {
      const role =
        this.authService.user.active_register.register.role.slug.split('-');
      this.applicableRole = role[0];
    } else {
      this.applicableRole = 'ADM';
    }

    this.getData();
    this.setPermissions();

    this.filterForm.controls.created_from.valueChanges
      .pipe(distinctUntilChanged((a: any, b: any) => a === b))
      .subscribe((values) => {
        const createdTo = this.filterForm.controls.created_to.value;

        if (values && values.length === 10) {
          this.filterForm.controls.created_to.setValidators([
            Validators.required,
            Utils.validateMaxMinToday,
            Utils.validateMinDate,
          ]);

          if (createdTo && createdTo.length === 10) {
            const isValid = Utils.verifyDateLessCurrentDate(createdTo, values);

            this.filterForm.controls.created_to.setErrors(
              !isValid ? null : { invalid: true }
            );

            return;
          }
        } else {
          this.filterForm.controls.created_to.setValidators([
            Utils.validateWhenNotEmpty(Utils.validateMaxMinToday),
            Utils.validateWhenNotEmpty(Utils.validateMinDate),
          ]);
        }

        this.filterForm.controls.created_to.updateValueAndValidity();
      });

    this.filterForm.controls.created_to.valueChanges
      .pipe(distinctUntilChanged((a: any, b: any) => a === b))
      .subscribe((values) => {
        const createdFrom = this.filterForm.controls.created_from.value;

        if (values && values.length === 10) {
          this.filterForm.controls.created_from.setValidators([
            Validators.required,
            Utils.validateMaxMinToday,
            Utils.validateMinDate,
          ]);

          if (createdFrom && createdFrom.length === 10) {
            const isValid = Utils.verifyDateLessCurrentDate(
              values,
              createdFrom
            );

            this.filterForm.controls.created_to.setErrors(
              !isValid ? null : { invalid: true }
            );
          }
        } else {
          this.filterForm.controls.created_from.setValidators([
            Utils.validateWhenNotEmpty(Utils.validateMaxMinToday),
            Utils.validateWhenNotEmpty(Utils.validateMinDate),
          ]);
        }

        this.filterForm.controls.created_from.updateValueAndValidity();
      });

    this.filterForm.controls.updated_from.valueChanges
      .pipe(distinctUntilChanged((a: any, b: any) => a === b))
      .subscribe((values) => {
        const updatedTo = this.filterForm.controls.updated_to.value;

        if (values && values.length === 10) {
          this.filterForm.controls.updated_to.setValidators([
            Validators.required,
            Utils.validateMaxMinToday,
            Utils.validateMinDate,
          ]);

          if (updatedTo && updatedTo.length === 10) {
            const isValid = Utils.verifyDateLessCurrentDate(updatedTo, values);

            this.filterForm.controls.updated_to.setErrors(
              !isValid ? null : { invalid: true }
            );

            return;
          }
        } else {
          this.filterForm.controls.updated_to.setValidators([
            Utils.validateWhenNotEmpty(Utils.validateMaxMinToday),
            Utils.validateWhenNotEmpty(Utils.validateMinDate),
          ]);
        }

        this.filterForm.controls.updated_to.updateValueAndValidity();
      });

    this.filterForm.controls.updated_to.valueChanges
      .pipe(distinctUntilChanged((a: any, b: any) => a === b))
      .subscribe((values) => {
        const updatedFrom = this.filterForm.controls.updated_from.value;

        if (values && values.length === 10) {
          this.filterForm.controls.updated_from.setValidators([
            Validators.required,
            Utils.validateMaxMinToday,
            Utils.validateMinDate,
          ]);

          if (updatedFrom && updatedFrom.length === 10) {
            const isValid = Utils.verifyDateLessCurrentDate(
              values,
              updatedFrom
            );

            this.filterForm.controls.updated_to.setErrors(
              !isValid ? null : { invalid: true }
            );
          }
        } else {
          this.filterForm.controls.updated_from.setValidators([
            Utils.validateWhenNotEmpty(Utils.validateMaxMinToday),
            Utils.validateWhenNotEmpty(Utils.validateMinDate),
          ]);
        }

        this.filterForm.controls.updated_from.updateValueAndValidity();
      });
  }

  getStatus(item: UserApprovalResponse) {
    if (item.completed) {
      if (item.is_approved) {
        return 'Aprovado internamente';
      } else {
        return 'Fundo rejeitado';
      }
    } else {
      if (item.current_department !== null) {
        return item.current_department.type === 'department'
          ? item.current_department.department.name
          : item.current_department.agent.name;
      } else if (item.step === 0) {
        return 'Edição';
      } else {
        return 'Departamento não definido';
      }
    }
  }

  getStatusInfo(item: UserApprovalResponse) {
    if (item.completed) {
      if (item.is_approved) {
        return 'Integração ao fundo';
      } else {
        if (item.current_department !== null) {
          return item.current_department.type === 'department'
            ? item.current_department.department.name
            : item.current_department.agent.name;
        } else if (item.step === 0) {
          return 'Edição';
        } else {
          return 'Departamento não definido';
        }
      }
    } else if (item.step === 0) {
      return '';
    } else {
      return `Aguardando aprovação (${item.step})`;
    }
  }

  getFundInitials(approval: any) {
    return approval.fund.name
      .toUpperCase()
      .replace(/[^\w\s]/gi, '')
      .split(' ')
      .slice(0, 2)
      .map((word: string) => word.charAt(0))
      .join('');
  }

  formatDocument(document: any) {
    return document.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
      '$1.$2.$3/$4-$5'
    );
  }

  resetForm() {
    this.loading = true;
    this.filterForm.reset();
    this.filterForm.controls.updated_from.setValidators([]);
    this.filterForm.controls.updated_to.setValidators([]);
    this.filterForm.controls.created_from.setValidators([]);
    this.filterForm.controls.created_to.setValidators([]);
    this.dataFilters = null;
    this.getFunds();
    this.showFilterModal = false;
  }

  async filterData() {
    this.loading = true;
    try {
      const values = this.filterForm.value;

      let filters = {};

      Object.entries(values).forEach(([key, value]: any) => {
        if (value !== null && value !== '') {
          filters[key] = value;
        }
      });

      const { count, data, offset } = await this.api.get<
        ApiResponse<UserApprovalResponse[]>
      >({
        route: 'api/approvals/user_approval/',
        token: true,
        params: {
          type: 'fund',
          page: 1,
          ...filters,
        },
      });

      this.dataFilters = filters;
      this.count = count;
      this.offset = offset;

      this.funds = data;
      this.loading = false;

      this.showFilterModal = false;
    } catch (error) {
      console.error(error);
      this.toast.show(
        'error',
        'Erro',
        'Ocorreu um erro ao carregar os dados de cedente.'
      );
    }
  }

  async getData() {
    this.loading = true;
    await Promise.all([this.getFunds(), this.getChoices()]);
  }

  async getChoices() {
    try {
      const res = await this.api.get({
        route: 'api/registration/new/fund-choices',
        token: true,
      });

      this.anbimaChoices = res.ANBIMA_CLASSIFICATION_CHOICES.map(
        (choice: any) => {
          return {
            label: choice.label,
            value: choice.id,
          };
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  async getFunds(page: number = 1) {
    try {
      const params = { ...this.dataFilters, page, type: 'fund' };

      const { count, data, offset } = await this.api.get({
        route: `api/approvals/user_approval/`,
        params,
        token: true,
      });

      //   const resTeste = await this.api.get({
      //     route: `api/registration/new/fund/`,
      //     token: true,
      //   });

      this.count = count;
      this.offset = offset;

      this.funds = data.sort((a: any, b: any) => {
        const aDate = new Date(a.updated_at);
        const bDate = new Date(b.updated_at);

        return bDate.getTime() - aDate.getTime();
      });

      const funds = data.map((approval: any) => {
        return {
          label: approval.fund.name,
          value: approval.fund.id,
        };
      });

      this.fundService.fundList = funds;
      this.fundService.onChange.emit();
      this.loading = false;
    } catch (error) {
      console.error(error);
    }
  }

  async removeFund(id: number) {
    await Swal.fire({
      title: 'Tem certeza?',
      text: 'Você não poderá reverter isso!',
      icon: 'warning',
      showConfirmButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sim, apague!',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.api
          .delete({
            route: 'api/fund',
            token: true,
            params: {
              id,
            },
          })
          .then((res) => {
            this.funds = this.funds.filter(
              (approval) => approval.fund.id !== id
            );

            Swal.fire({
              title: 'Apagado!',
              text: 'Fundo apagado com sucesso',
              icon: 'success',
              confirmButtonColor: '#3085d6',
            });
          })
          .catch((error) => {
            Swal.fire({
              title: 'Erro!',
              text: 'Erro ao apagar fundo',
              icon: 'error',
              confirmButtonColor: '#3085d6',
            });
          });
      },
    });
  }

  changePageData(page: number) {
    this.loading = true;
    this.getFunds(page);
  }

  redirectToNewFund() {
    this.router.navigate(['/app/funds/new']);
  }

  setPermissions() {
    const permissions = Object.keys(this.permissionObj).map(
      (key) => `de_can_${key.split('can').join('').toLowerCase()}_fund`
    );

    permissions.forEach((permission, idx) => {
      this.permissionObj[Object.keys(this.permissionObj)[idx]] =
        this.authService.verifyPermission(permission);
    });
  }
}
