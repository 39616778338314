<div class="dashboard h-100">
    <div class="dashboard-card no-padding d-flex flex-column justify-content-between"
        style="max-width: 800px">
        <div class="row">
            <div class="col-12">
                <div class="padding-container d-flex flex-column"
                    *ngIf="contributorsLoading">
                    <ngx-skeleton-loader [theme]="{
                            height: '24px',
                            width: '30px',
                            margin:'0'
                          }">
                    </ngx-skeleton-loader>

                    <ngx-skeleton-loader [theme]="{
                                height: '27px',
                                width: '100px',
                            marginBottom:'30px'
                              }">
                    </ngx-skeleton-loader>
                </div>

                <div class="padding-container d-flex flex-column"
                    *ngIf="!contributorsLoading && contributors.length > 0">
                    <span class="card-info-number">{{contributors.length
                        > 9 ? '' :
                        0}}{{contributors.length}}</span>
                    <span class="card-info-title">Colaboradores</span>
                </div>
            </div>

            <div class="col-12 dashboard-card-body">
                <ng-container *ngIf="contributorsLoading">
                    <table #table>
                        <thead>
                            <tr>
                                <th>
                                    <ngx-skeleton-loader [theme]="{
                                    height: '15px',
                                    width: '125px',
                                    marginBottom:'0'                            
                                  }">
                                    </ngx-skeleton-loader>
                                </th>

                                <th class="text-right">
                                    <ngx-skeleton-loader [theme]="{
                                    height: '15px',
                                    width: '125px',
                                    marginBottom:'0'                            
                                  }">
                                    </ngx-skeleton-loader>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let number of [0,1,2,3]">
                                <td>
                                    <ngx-skeleton-loader [theme]="{
                                        height: '15px',
                                        width: '100px',
                                    marginBottom:'0'                            
                                      }">
                                    </ngx-skeleton-loader>
                                </td>
                                <td class="item-number">
                                    <ngx-skeleton-loader [theme]="{
                                    height: '15px',
                                    width: '30px',
                                    marginBottom:'0'
                                  }">
                                    </ngx-skeleton-loader>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ng-container>

                <table #table
                    *ngIf="!contributorsLoading && contributors.length > 0">
                    <thead>
                        <tr>
                            <th>COLABORADOR</th>
                            <th style="text-align:right;">DEPARTAMENTO
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let contributor of contributors.slice(0,10)">
                            <td>{{contributor.first_name + ' ' +
                                contributor.last_name}}</td>
                            <td style="text-align:right;">
                                {{contributor.department.name}}
                            </td>
                            <td class="pl-0">
                                <div class="table-options">
                                    <ng-container
                                        *ngIf="permissionObj.canDelete">
                                        <button
                                            (click)="removeContributors(contributor.id)">
                                            <i class="fas fa-trash"></i>
                                        </button>
                                        <app-spacer [right]="19"></app-spacer>
                                    </ng-container>

                                    <button
                                        (click)="setContributors(contributor)"
                                        *ngIf="permissionObj.canChange">
                                        <i class="fas fa-pen"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="col-12">
                <div class="padding-container d-flex flex-row justify-content-between"
                    *ngIf="contributorsLoading">
                    <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                        width: '180px',
                            marginBottom:'0'                            
                              }">
                    </ngx-skeleton-loader>

                    <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                width: '180px',
                                marginBottom:'0'                            
                                  }">
                    </ngx-skeleton-loader>
                </div>

                <div class="padding-container d-flex flex-row justify-content-between"
                    *ngIf="!contributorsLoading && contributors.length > 0">

                    <app-button [inverted]="true"
                        (onClick)="redirect('/app/organizations/collaborators/')">Ver
                        todos
                    </app-button>
                    <app-spacer [right]="8"></app-spacer>

                    <app-button (click)="addContributorsToggle(true)"
                        class="add-button" *ngIf="permissionObj.canAdd">
                        <i class="fas fa-plus-circle mr-2"></i>
                        Adicionar colaborador
                    </app-button>
                </div>
            </div>
        </div>

        <div class="row"
            *ngIf="contributors.length === 0 && !contributorsLoading">
            <div class="col-12">
                <div class="empty-card">

                    <div class="img-container">
                        <img src="/assets/images/collaborator-icon.png"
                            alt="icone">
                    </div>

                    <ng-container *ngIf="!permissionObj.canAdd">
                        <h3>
                            Ainda não existem colaboradores cadastrados
                        </h3>
                    </ng-container>

                    <ng-container *ngIf="permissionObj.canAdd">
                        <h3>Adicione o primeiro colaborador</h3>
                        <p>
                            Envie convites aos colaboradores da sua
                            organização a qualquer momento, sem limite
                        </p>

                        <app-button (click)="addContributorsToggle(true)"
                            class="add-button">
                            <i class="fas fa-plus-circle mr-2"></i>Adicionar
                            colaborador
                        </app-button>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>


<app-side-modal [active]="addContributors" [title]="renderTitle()"
    (onClose)="closeModal()">
    <ng-container *ngIf="addContributors">
        <div class="row align-items-center" [formGroup]="contributorsForm">
            <div class="col-8">
                <div class="d-flex align-items-center">
                    <app-masked-input label="Documento do colaborador"
                        [group]="contributorsForm" controlName="document"
                        mask="000.000.000-00" placeholder="123.456.789-01">
                    </app-masked-input>
                    <i *ngIf="searchingRegister"
                        class="fas fa-circle-notch fa-spin app-button-icon ml-3 mt-4"></i>
                </div>
                <app-spacer [bottom]="24"></app-spacer>
            </div>


            <ng-container *ngIf="fetchedRegister">
                <ng-container *ngIf="!foundedRegister">
                    <div class="col-6">
                        <app-input label="Nome do novo colaborador"
                            [group]="contributorsForm" controlName="name">
                        </app-input>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>

                    <div class="col-6">
                        <app-input
                            label="Informe o email para receber o convite"
                            [group]="contributorsForm" controlName="email">
                        </app-input>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>
                </ng-container>

                <div class="col-12 col-lg-6">
                    <app-select [items]="departmentOptions" label="Departamento"
                        placeholder="Selecione o dpto"
                        helpText="Sem abreviações" controlName="department"
                        [group]="contributorsForm">
                    </app-select>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>

                <div class="col-12">
                    <p>Tipo de usuário</p>
                    <app-spacer [bottom]="16"></app-spacer>

                    <div class="d-flex">
                        <app-radio [multiple]="true"
                            (onCheckChanged)="handleChangeValue('simple')"
                            [isChecked]="isSimple">
                            <div class="flex-1 d-flex flex-column">
                                <span class="pep-title">Usuário comum</span>
                            </div>
                        </app-radio>
                        <app-spacer [right]="24"></app-spacer>

                        <app-radio [multiple]="true"
                            (onCheckChanged)="handleChangeValue('owner')"
                            [isChecked]="isOwner">
                            <div class="flex-1 d-flex flex-column">
                                <span class="pep-title">Proprietário</span>
                            </div>
                        </app-radio>

                        <app-spacer [right]="24"></app-spacer>

                        <app-radio [multiple]="true"
                            (onCheckChanged)="handleChangeValue('manager')"
                            [isChecked]="isManager">
                            <div class="flex-1 d-flex flex-column">
                                <span class="pep-title">Usuário master</span>
                            </div>
                        </app-radio>
                    </div>
                    <app-spacer [bottom]="48"></app-spacer>
                </div>

                <div class="col-12">
                    <h5>Vincule esse usuário a um grupo de permissões de uma
                        empresa
                    </h5>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

                <div class="col-12">
                    <app-radio [multiple]="true"
                        (onCheckChanged)="handleChangeAllCompanies()"
                        [isChecked]="allCompanies">
                        <div class="flex-1 d-flex flex-column">
                            <span class="pep-title">Todas as empresas</span>
                        </div>
                    </app-radio>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

                <div class="col-12">
                    <app-select [items]="companyOptions" [group]="companyForm"
                        controlName="company" [readOnly]="allCompanies"
                        placeholder="Selecione" label="Empresa">
                    </app-select>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

                <div class="col-12">
                    <app-select [items]="permissionGroupsOptions"
                        [multiple]="true" [group]="companyForm"
                        controlName="permission_group" [readOnly]="allCompanies"
                        placeholder="Selecione" label="Grupo de usuários">
                    </app-select>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

                <div class="col-12 d-flex  justify-content-end">
                    <app-button (onClick)="companyForm.reset()"
                        class="add-button" [phantom]="true">
                        Cancelar
                    </app-button>
                    <app-spacer [right]="8"></app-spacer>

                    <app-button (onClick)="addCompanyToArr()"
                        [disabled]="companyForm.invalid || allCompanies"
                        class="add-button" [loading]="submiting">
                        Vincular à empresa
                    </app-button>
                </div>

                <div *ngIf="companiesArr.length > 0 && !allCompanies">
                    <app-spacer [top]="32"></app-spacer>
                    <table>
                        <thead>
                            <tr>
                                <th>Empresa</th>
                                <th>Grupo de permissão</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of companiesArr">
                                <td>{{item.companyName}}</td>
                                <td>{{groupNameRender(item.permissionGroupName)}}
                                </td>
                                <td>
                                    <button class="dash-item-button"
                                        (click)="removeCompany(item)">
                                        <i class="fas fa-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="col-12 col-lg-6"></div>

                <div class="col-12 col-lg-6 text-right">
                    <app-spacer [top]="48"></app-spacer>

                    <app-button [disabled]="getDisabled()"
                        (click)="addCollaborator()">Salvar colaborador
                    </app-button>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>
            </ng-container>
        </div>
    </ng-container>
</app-side-modal>