
const gitInfo = {
  branch: "master",
  commit: "4482966",
  origin: "https://bitbucket.org/prosperitainova/dataengine-web-angular",
  url: "https://bitbucket.org/prosperitainova/dataengine-web-angular/commits/448296631c0e6c39b428dd40680f94a9f9c66f5f",
  date: "23/01/2025",
};

export default gitInfo;
  