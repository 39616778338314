import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import {
  CompanyFormKeys,
  SignupService,
} from 'src/app/services/signup/signup.service';

@Component({
  selector: 'app-signup-unified-company-complementary',
  templateUrl: './signup-unified-company-complementary.component.html',
  styleUrls: ['./signup-unified-company-complementary.component.scss'],
})
export class SignupUnifiedCompanyComplementaryComponent implements OnInit {
  tabs: Tab[] = [];

  tabIndex = 0;
  role: string | null = null;

  title = '';

  stepSlug: ComplementaryCompanySteps = 'complementaryGeneral';

  constructor(
    public signupService: SignupService,
    private authService: AuthService,
    private router: Router,
    private api: ApiService
  ) {}

  ngOnInit(): void {
    console.log('teste complementary 0');
    let urlParts = this.router.url.split('/pj')[0].split('/');
    let range = urlParts.length;
    this.role = urlParts[range - 1];
    this.stepSlug = this.signupService.getComplementaryCompanyStep();
    this.setTitle();

    console.log('teste complementary 0.1');

    this.tabs = [
      {
        label: 'Geral',
        error: !this.signupService.companyFormValid.complementaryGeneral,
        errorKey: 'complementaryGeneral',
      },
      {
        label: 'Endereço',
        error: !this.signupService.companyFormValid.complementaryAddress,
        errorKey: 'complementaryAddress',
      },
      {
        label: 'Dados bancários',
        error: !this.signupService.companyFormValid.complementaryBank,
        errorKey: 'complementaryBank',
      },
      {
        label: 'Composição acionária',
        error: !this.signupService.companyFormValid.complementaryComposition,
        errorKey: 'complementaryComposition',
        disabled:
          this.authService.user.active_register.register.role.slug ===
          `${this.role.toUpperCase()}-PJ-FI`,
        hided:
          this.authService.user.active_register.register.role.slug ===
          `${this.role.toUpperCase()}-PJ-FI`,
      },
      {
        label: 'Grupo econômico',
        error: !this.signupService.companyFormValid.complementaryEconomic,
        errorKey: 'complementaryEconomic',
        disabled:
          this.authService.user.active_register.register.role.slug ===
          `${this.role.toUpperCase()}-PJ-FI`,
        hided:
          this.authService.user.active_register.register.role.slug ===
          `${this.role.toUpperCase()}-PJ-FI`,
      },
      {
        label: 'Fundos de interesse',
        error: !this.signupService.companyFormValid.complementaryBond,
        errorKey: 'complementaryBond',
        disabled:
          this.authService.user.active_register.register.role.slug ===
          `${this.role.toUpperCase()}-PJ-FI`,
        hided:
          this.authService.user.active_register.register.role.slug ===
          `${this.role.toUpperCase()}-PJ-FI`,
      },
      {
        label: 'Representantes',
        error:
          !this.signupService.companyFormValid.complementaryRepresentatives,
        errorKey: 'complementaryRepresentatives',
      },
      {
        label: 'Administrador',
        error: !this.signupService.companyFormValid.complementaryAdmin,
        errorKey: 'complementaryAdmin',
        disabled:
          this.authService.user.active_register.register.role.slug ===
          `${this.role.toUpperCase()}-PJ-FI`,
        hided:
          this.authService.user.active_register.register.role.slug ===
          `${this.role.toUpperCase()}-PJ-FI`,
      },
      {
        label: 'Gestor',
        error: !this.signupService.companyFormValid.complementaryAddress,
        errorKey: 'complementaryAddress',
        disabled:
          this.authService.user.active_register.register.role.slug ===
          `${this.role.toUpperCase()}-PJ-FI`,
        hided:
          this.authService.user.active_register.register.role.slug ===
          `${this.role.toUpperCase()}-PJ-FI`,
      },
    ];

    this.signupService.companyStatusChangeEvent.subscribe(() => {
      this.tabs.forEach((tab) => {
        const key: CompanyFormKeys = tab.errorKey ?? '';

        tab.error = !this.signupService.companyFormValid[key] ?? false;
      });
    });

    if (this.role === 'provider') {
      this.signupService.setCompanyFormStatus('complementaryBank', true);
      this.signupService.setCompanyFormStatus('complementaryBond', true);
    }

    if (
      this.authService.user.active_register.register.role.slug ===
      `${this.role.toUpperCase()}-PJ-FI`
    ) {
      this.signupService.setCompanyFormStatus('complementaryEconomic', true);
      this.signupService.setCompanyFormStatus('complementaryComposition', true);
    }

    this.signupService.setCompanyFormStatus('complementaryEconomic', true);

    this.signupService.changeCompanyStepEvent.subscribe((step) => {
      console.log(
        'teste complementary 1',
        `complementary${step.charAt(0).toUpperCase() + step.slice(1)}`
      );
      const aux = `complementary${
        step.charAt(0).toUpperCase() + step.slice(1)
      }` as ComplementaryCompanySteps;

      this.stepSlug = aux;

      this.setTitle();
    });

    console.log('teste complementary 2');
  }

  setTitle() {
    switch (this.stepSlug) {
      case 'complementaryGeneral':
        this.title = 'Geral';
        break;

      case 'complementaryAddress':
        this.title = 'Endereço';
        break;

      case 'complementaryBank':
        this.title = 'Dados bancários';
        break;

      case 'complementaryComposition':
        this.title = 'Composição acionária';
        break;

      case 'complementaryEconomic':
        this.title = 'Grupo econômico';
        break;

      case 'complementaryBond':
        this.title = 'Fundos de interesse';
        break;

      case 'complementaryRepresentatives':
        this.title =
          this.role === 'assignor'
            ? 'Representantes e Avalistas/Dev. Solidários'
            : 'Representantes';
        break;

      case 'complementaryAdmin':
        this.title = 'Administrador';
        break;

      case 'complementaryManager':
        this.title = 'Gestor';
        break;

      default:
        break;
    }
  }
}
