import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Utils from 'src/app/helpers/utils';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-dashboard-request-acess',
  templateUrl: './dashboard-request-acess.component.html',
  styleUrls: ['./dashboard-request-acess.component.scss'],
})
export class DashboardRequestAcessComponent implements OnInit {
  form = this.formBuilder.group({
    document: [null, [Validators.required, Utils.validateCpfCnpj]],
  });

  requestForm = this.formBuilder.group({
    fund: [null, [Validators.required]],
  });

  fundOptions: SelectItem[] = [];
  accounts: any[] = [];

  loading = false;
  alreadyHaveAccess = false;
  userNotFound = false;
  sending = false;
  documentChecked = false;
  requestModal = false;
  selectedId = '';

  constructor(
    private formBuilder: FormBuilder,
    private api: ApiService,
    private toastService: ToastService,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.authService.permissionsLoadedEvent.subscribe(() => {
      this.authService.canViewPage(['de_can_view_access']);
    });

    this.getFunds();
    this.getAccounts();
  }

  renderTitle() {
    let title = 'Este documento já está cadastrado';
    if (this.alreadyHaveAccess) title = 'Você já tem acesso a este registro';
    if (this.userNotFound) title = 'Este documento não está cadastrado';
    return title;
  }

  renderSubtitle() {
    let subtitle =
      'Por questões de segurança e proteção de dados, é necessário solicitar a autorização dos responsáveis para acessar o cadastro.';
    if (this.alreadyHaveAccess)
      subtitle = 'Você pode acessar o cadastro na aba registros';
    if (this.userNotFound)
      subtitle =
        'Não foi encontrado nenhum registro com este documento, por favor, verifique se o documento está correto.';
    return subtitle;
  }

  renderImg() {
    let img = '/assets/images/forgot-password-icon.png';

    if (this.alreadyHaveAccess) img = '/assets/images/collaborator-icon.png';
    if (this.userNotFound) img = '/assets/images/figure_3d_search.png';

    return img;
  }

  async getFunds() {
    try {
      const res = await this.api.get({
        route: 'api/registration/fund',
        token: true,
      });

      const filteredArr = Utils.filterRepeatedValues(res.funds, 'id');

      const funds = filteredArr.map((fund: any) => {
        return {
          label: fund.name,
          value: fund.id,
        };
      });

      this.fundOptions = funds;
    } catch (error) {
      console.log(error);
    }
  }

  async getAccounts() {
    try {
      const { data } = await this.api.get<ApiResponse<RegisterUserResponse[]>>({
        route: 'api/registration/register_user/',
        token: true,
      });

      this.accounts = data;
    } catch (error) {
      console.error(error);
    }
  }

  async checkDocument() {
    this.loading = true;
    try {
      const query = Utils.onlyNumbers(this.form.controls.document.value);
      const response = await this.api.get({
        route: 'api/registration/search/register',
        params: { query },
        token: true,
      });

      this.documentChecked = true;

      this.alreadyHaveAccess = false;

      this.userNotFound = false;

      if (response.data === null) this.userNotFound = true;

      if (response.data) {
        const idArr = this.accounts.map((account) => account.register.id);

        const checkId = idArr.includes(response.data.id);

        if (checkId) {
          this.alreadyHaveAccess = true;
        } else {
          this.selectedId = response.data.id;
        }
      }
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }

  async sendRequest() {
    this.sending = true;
    try {
      const register_id = this.selectedId;

      const response = await this.api.post({
        route: 'api/registration/request/register',
        body: { register_id },
        token: true,
      });

      this.sending = false;

      this.toastService.show('info', 'Ok', 'Solicitação enviada com sucesso!');
      this.router.navigateByUrl(`/app/dashboard`);
    } catch (error) {
      console.log(error);
    }

    this.sending = false;
    this.toggleModal();
  }

  toggleModal() {
    this.requestModal = !this.requestModal;
  }
}
