import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FormBuilder, Validators } from '@angular/forms';
import Utils from 'src/app/helpers/utils';
import Swal from 'sweetalert2';
import { MaskPipe } from 'ngx-mask';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-dashboard-contributors-collaborators',
  templateUrl: './dashboard-contributors-collaborators.component.html',
  styleUrls: ['./dashboard-contributors-collaborators.component.scss'],
})
export class DashboardContributorsCollaboratorsComponent
  implements OnInit, OnChanges
{
  @Input() departments: any[];

  contributorsForm = this.formBuilder.group({
    document: [null, [Validators.required]],
    name: [null, []],
    email: [null, []],
    department: [null, [Validators.required]],
  });

  companyForm = this.formBuilder.group({
    company: [null, [Validators.required]],
    permission_group: [null, [Validators.required]],
  });

  permissionObj: PermissionValues = {
    canAdd: false,
    canChange: false,
    canDelete: false,
  };

  contributorsLoading = false;
  addContributors = false;

  contributors: any[] = [];

  searchingRegister = false;
  contributor: any = undefined;

  departmentOptions: SelectItem[] = [];
  permissionGroupsOptions: SelectItem[] = [];
  companyOptions: SelectItem[] = [];

  fetchedRegister = false;
  foundedRegister = false;
  firstOpen = true;
  isSimple = false;
  isOwner = false;
  isManager = false;
  submiting = false;
  allCompanies = false;

  companiesArr: any[] = [];

  constructor(
    private api: ApiService,
    private toast: ToastService,
    private formBuilder: FormBuilder,
    private maskPipe: MaskPipe,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnChanges() {
    this.setDepartments();
  }

  ngOnInit(): void {
    this.getContributors();
    this.setDepartments();
    this.setPermissions();
    this.getCompanies();

    this.companyForm.controls.company.valueChanges.subscribe((value) => {
      this.companyForm.controls.permission_group.setValue(null);
      this.getPermissionGroups();
    });

    this.contributorsForm.controls.document.valueChanges
      .pipe(debounceTime(100), distinctUntilChanged())
      .subscribe((values: any) => {
        if (this.contributor && this.firstOpen) {
          this.firstOpen = false;
          this.fetchedRegister = true;
          return;
        } else {
          const formatted = Utils.onlyNumbers(values);

          if (
            formatted.length === 11 &&
            this.contributor &&
            formatted === this.contributor.username
          ) {
            this.fetchedRegister = true;
            this.foundedRegister = false;
            this.setValues();
            return;
          }

          this.handleDocumentBlur();
        }
      });
  }

  async getPermissionGroups() {
    try {
      const company = this.companyForm.controls.company.value;

      const res = await this.api.get({
        route: `api/v2/user_group/?company=${company}`,
        token: true,
      });

      this.permissionGroupsOptions = res.map((item: PermissionListValues) => ({
        label: item.group,
        value: item.id,
      }));

      if (company && this.permissionGroupsOptions.length === 0) {
        this.toast.show(
          'error',
          'Erro',
          'É necessário criar um grupo de permissão para essa empresa.'
        );
      }
    } catch (error) {
      console.log(error);
      this.toast.show(
        'error',
        'Erro',
        'Ocorreu um erro ao carregar grupos de permissão.'
      );
    }
  }

  handleChangeAllCompanies() {
    this.companyForm.controls.company.setValue(null);
    this.companyForm.controls.permission_group.setValue(null);
    this.allCompanies = !this.allCompanies;

    if (this.allCompanies) {
      this.companyForm.controls.company.setValidators(null);
      this.companyForm.controls.permission_group.setValidators(null);
    } else {
      this.companyForm.controls.company.setValidators([Validators.required]);
      this.companyForm.controls.permission_group.setValidators([
        Validators.required,
      ]);
    }

    this.companyForm.controls.company.updateValueAndValidity();
    this.companyForm.controls.permission_group.updateValueAndValidity();
  }

  setDepartments() {
    const options = this.departments.map((_department) => ({
      label: _department.name,
      value: _department.id,
    }));

    this.departmentOptions = options;
  }

  async fetchRegister() {
    this.fetchedRegister = false;
    this.searchingRegister = true;
    try {
      const { document } = this.contributorsForm.value;

      const documentNumber = Utils.onlyNumbers(document);

      const { exists } = await this.api.post<ValidateFieldResponse>({
        route: 'validate-username-exists/',

        body: { username: documentNumber },
      });

      this.foundedRegister = exists;

      if (exists) {
        this.contributorsForm.controls.name.setValidators([]);
        this.contributorsForm.controls.email.setValidators([]);

        this.toast.show(
          'info',
          'Atenção',
          'Cadastro já existente, preencha os dados abaixo para atualizar'
        );
      } else {
        if (!this.contributor) {
          this.resetFields();
        }

        this.contributorsForm.controls.name.setValidators([
          Validators.required,
        ]);
        this.contributorsForm.controls.email.setValidators([
          Validators.required,
          Validators.email,
        ]);
      }

      this.contributorsForm.controls.name.updateValueAndValidity();
      this.contributorsForm.controls.email.updateValueAndValidity();

      this.fetchedRegister = true;
    } catch (error) {
      this.fetchedRegister = true;
    }

    this.searchingRegister = false;
  }

  async removeContributors(id: number) {
    const { isConfirmed } = await Swal.fire({
      title: 'Tem certeza?',
      text: 'Você não poderá reverter isso!',
      icon: 'warning',
      showConfirmButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sim, apague!',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
    });

    if (isConfirmed) {
      try {
        this.api.delete({
          route: `api/collaborator/${id}/`,
          token: true,
        });

        this.contributors = this.contributors.filter(
          (contributor) => contributor.id !== id
        );

        Swal.fire({
          title: 'Apagado!',
          text: 'Departamento apagado com sucesso',
          icon: 'success',
          confirmButtonColor: '#3085d6',
        });
      } catch (error) {
        Swal.fire({
          title: 'Erro!',
          text: 'Erro ao apagar departamento',
          icon: 'error',
          confirmButtonColor: '#3085d6',
        });
      }
    }
  }

  addContributorsToggle(status: boolean) {
    this.addContributors = status;
  }

  handleChangeValue(type: string) {
    if (type === 'manager') {
      this.isManager = !this.isManager;
      if (this.isManager) {
        this.isSimple = false;
        this.isOwner = false;
      }
    }

    if (type === 'simple') {
      this.isSimple = !this.isSimple;
      if (this.isSimple) {
        this.isManager = false;
        this.isOwner = false;
      }
    }

    if (type === 'owner') {
      this.isOwner = !this.isOwner;
      if (this.isOwner) {
        this.isSimple = false;
        this.isManager = false;
      }
    }
  }

  async addCollaborator() {
    this.submiting = true;

    try {
      const values = this.contributorsForm.value;

      let contribuitorId = this.contributor ? this.contributor.id : null;

      let alreadyHaveEmail = null;

      let payload: any = {
        username: Utils.onlyNumbers(values.document),
        first_name: 'Dataengine',
        last_name: 'Collaborator',
        email: 'dataengine@prosperita.io',
        department: values.department,
        is_approver: values.is_approver ?? false,
      };

      const alreadyHaveDocument = this.contributors.find(
        (item) => item.username === payload.username
      );

      if (!this.foundedRegister) {
        const [first_name, ...last_name] = values.name.split(' ');

        payload = {
          ...payload,
          first_name: first_name,
          last_name: last_name.join(' '),
          email: values.email,
        };

        alreadyHaveEmail = this.contributors.find(
          (item) => item.email === payload.email
        );

        if (alreadyHaveEmail && !this.contributor) {
          this.toast.show(
            'error',
            'Erro!',
            `Colaborador com e-mail
             já cadastrado`
          );
          return;
        }
      }

      if (this.foundedRegister) {
        if (alreadyHaveDocument) {
          if (payload.username === this.contributor?.username) {
            payload = {
              ...payload,
              first_name: values.name,
              last_name: '',
              email: values.email,
            };
          } else {
            contribuitorId = alreadyHaveDocument.id;

            const selectedCollaborator = this.contributors.find(
              (item) => item.username === payload.username
            );

            payload = {
              username: Utils.onlyNumbers(values.document),
              first_name: selectedCollaborator.first_name,
              last_name: selectedCollaborator.last_name,
              email: selectedCollaborator.email,
              department: values.department,
              is_approver: values.is_approver ?? false,
            };
          }
        }
      }

      const permissionsGroupsArrReduced = this.companiesArr.reduce(
        (acc, item) => {
          item.permissionGroupId.forEach((id) => {
            acc.push(id);
          });
          return acc;
        },
        []
      );

      const userPayload = {
        username: Utils.onlyNumbers(values.document),
        permission_group: this.allCompanies ? [] : permissionsGroupsArrReduced,
        simple_user: this.isSimple,
        manage_user: this.isManager,
        owner: this.isOwner,
        all_company: this.allCompanies,
        company: this.allCompanies
          ? []
          : this.companiesArr.map((item) => item.companyId),
      };

      const res =
        this.contributor || (this.foundedRegister && alreadyHaveDocument)
          ? await this.api.put({
              route: `api/collaborator/${contribuitorId}/`,
              token: true,
              body: payload,
            })
          : await this.api.post({
              route: 'api/collaborator/',
              token: true,
              body: payload,
            });

      const userRes = await this.api.post({
        route: 'api/v2/user_group_user/',
        token: true,
        body: userPayload,
      });

      if (contribuitorId) {
        this.toast.show(
          'info',
          'Sucesso!',
          'Colaborador atualizado com sucesso'
        );
      } else {
        this.toast.show('info', 'Sucesso!', 'Colaborador adicionado');
      }

      this.closeModal();
      this.getContributors();
    } catch (error) {
      console.error(error);
      this.toast.show('error', 'Erro!', 'Erro ao adicionar colaborador');
    }
    this.submiting = true;
  }

  async getCompanies() {
    try {
      const res = await this.api.get({
        route: 'api/v2/company/',
        token: true,
      });

      this.companyOptions = res.map((item: PermissionCompany) => ({
        label: item.description,
        value: item.id,
      }));
    } catch (error) {
      console.log(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar empresas.');
    }
  }

  renderTitle() {
    return this.contributor
      ? `Colaborador(a) ${this.contributor.first_name}`
      : ' Novo colaborador';
  }

  renderSubtitle() {
    return this.contributor
      ? `Atualize os dados de ${
          this.contributor.first_name + ' ' + this.contributor.last_name
        }`
      : 'Adicione um novo colaborador ao sistema';
  }

  redirect(url: string) {
    this.router.navigate([url]);
  }

  closeModal() {
    this.contributorsForm.reset();
    this.addContributors = false;
    this.contributor = undefined;
    this.firstOpen = true;
    this.fetchedRegister = false;
    this.allCompanies = true;
    this.handleChangeAllCompanies();
    this.companiesArr = [];
  }

  setContributors(contributor: any) {
    this.contributor = contributor;
    this.fetchedRegister = true;
    this.foundedRegister = false;
    this.setValues();
    this.addContributorsToggle(true);
  }

  setValues() {
    const contributor = this.contributor;
    this.contributorsForm.patchValue({
      name: `${contributor.first_name} ${contributor.last_name}`,
      email: contributor.email,
      document: this.maskPipe.transform(contributor.username, '000.000.000-00'),

      department: contributor.department.id,
    });
  }

  async handleDocumentBlur() {
    const { document } = this.contributorsForm.value;
    const formatted = Utils.onlyNumbers(document);

    if (formatted.length === 11) {
      this.fetchRegister();
    }
  }

  resetFields() {
    this.contributorsForm.controls.name.setValue(null);
    this.contributorsForm.controls.email.setValue(null);
    this.contributorsForm.controls.department.setValue(null);
    this.contributorsForm.controls.is_approver.setValue(false);
  }

  async getContributors() {
    this.contributorsLoading = true;
    try {
      const { data } = await this.api.get({
        route: 'api/collaborator/',
        token: true,
      });

      this.contributors = data;
    } catch (error) {
      this.toast.show('error', 'Erro!', 'Erro ao recuperar colaboradores');
    }
    this.contributorsLoading = false;
  }

  setPermissions() {
    const permissions = Object.keys(this.permissionObj).map(
      (key) => `de_can_${key.split('can').join('').toLowerCase()}_collaborator`
    );

    permissions.forEach((permission, idx) => {
      this.permissionObj[Object.keys(this.permissionObj)[idx]] =
        this.authService.verifyPermission(permission);
    });
  }

  getDisabled() {
    const userType = this.isManager || this.isSimple || this.isOwner;

    return (
      this.contributorsForm.invalid ||
      this.submiting ||
      !userType ||
      (!this.allCompanies && this.companiesArr.length === 0)
    );
  }

  removeCompany(index: number) {
    this.companiesArr.splice(index, 1);
  }

  addCompanyToArr() {
    const alreadyExists = this.companiesArr.some(
      (item) => item.companyId === this.companyForm.controls.company.value
    );

    if (alreadyExists) {
      this.toast.show('error', 'Erro', 'Essa empresa já foi adicionada.');
      this.companyForm.reset();
      return;
    }

    const company = this.companyForm.controls.company.value;
    const permissionGroup = this.companyForm.controls.permission_group.value;

    const permissionGroupNames = permissionGroup.map(
      (item) =>
        this.permissionGroupsOptions.find((pg) => pg.value === item).label
    );

    const obj = {
      companyId: company,
      companyName: this.companyOptions.find((item) => item.value === company)
        .label,
      permissionGroupId: permissionGroup,
      permissionGroupName: permissionGroupNames,
    };

    this.companiesArr.push(obj);

    this.companyForm.reset();
  }

  groupNameRender(groups: string[]) {
    return groups.join(', ');
  }
}
