import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import {
  CompanyFormKeys,
  SignupService,
} from 'src/app/services/signup/signup.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import UIDefaultTheme from 'src/themes';
import Utils from 'src/app/helpers/utils';

@Component({
  selector: 'app-signup-unified-company',
  templateUrl: './signup-unified-company.component.html',
  styleUrls: ['./signup-unified-company.component.scss'],
})
export class SignupUnifiedCompanyComponent implements OnInit {
  steps: Step[] = [
    {
      icon: 'fas fa-file-alt',
      svg: 'assets/images/icon-inputdata-active.svg',
      title: 'Dados complementares',
      slug: 'complementary',
      substeps: [
        {
          title: 'Geral',
          slug: 'complementaryGeneral',
        },
        {
          title: 'Endereço',
          slug: 'complementaryAddress',
        },
        {
          title: 'Dados bancários',
          slug: 'complementaryBank',
        },
        {
          title: 'Composição acionária',
          slug: 'complementaryComposition',
        },
        {
          title: 'Grupo econômico',
          slug: 'complementaryEconomic',
        },
        {
          title: 'Fundos de interesse',
          slug: 'complementaryBond',
        },
        {
          title: 'Representantes',
          slug: 'complementaryRepresentatives',
        },
      ],
    },
    {
      icon: 'fas fa-file-alt',
      title: 'Resumo dos poderes',
      svg: 'assets/images/icon-inputdata-active.svg',
      slug: 'powers',
    },
    {
      icon: 'fas fa-paperclip',
      svg: 'assets/images/icon-attachments.svg',
      title: 'Anexos & comprovantes',
      slug: 'attachments',
    },
  ];

  shareholderSteps: Step[] = [
    {
      icon: 'fas fa-paperclip',
      svg: 'assets/images/check-list.svg',
      title: 'Rendimentos & patrimônios',
      slug: 'income',
    },
    {
      icon: 'fas fa-paperclip',
      svg: 'assets/images/check-list.svg',
      title: 'Declarações & autorizações',
      slug: 'declarations',
    },
    {
      icon: 'fas fa-paperclip',
      svg: 'assets/images/check-list.svg',
      title: 'Análise de perfil de investidor',
      slug: 'analysis',
    },
    {
      icon: 'fas fa-paperclip',
      svg: 'assets/images/check-list.svg',
      title: 'Declaração de Condição de Investidor',
      slug: 'conditions',
    },
    {
      icon: 'fas fa-paperclip',
      svg: 'assets/images/check-list.svg',
      title: 'Declaração US Person / Non US Person (FATCA)',
      slug: 'tax',
    },
  ];

  userCredentials: UserCredentials = {
    email: '',
    name: '',
  };

  userOptionsOpen: boolean = false;

  isShareholder = false;

  _activeStep: number = 0;

  activeSubstep: ComplementaryCompanySteps = 'complementaryRepresentatives';

  redirected = false;
  hasMultipleAccounts = false;

  get activeStep() {
    return this._activeStep;
  }

  set activeStep(value) {
    this._activeStep = value;

    // TODO arrumar a lógica dps

    if (this.isShareholder) {
      if (value === 6) this.currentTab = 'Anexos & comprovantes';

      if (this.isFund)
        if (value === 4) this.currentTab = 'Anexos & comprovantes';
    } else {
      if (value === 2) this.currentTab = 'Anexos & comprovantes';
    }
  }

  currentTab: string = '';

  showSupportModal = false;

  name: string | null = null;
  role: string | null = null;
  label: string | null = null;

  agentOptions: SelectItem[] = [];

  isFund = false;
  isProvider = false;

  accounts: RegisterUserResponse[] = [];

  public UIDefaultTheme = UIDefaultTheme;

  constructor(
    private router: Router,
    public signupService: SignupService,
    private apiService: ApiService,
    private toastService: ToastService,
    private authService: AuthService,
    private titleService: Title
  ) {
    if (this.authService.user.active_register.register.role.slug) {
      this.titleService.setTitle(
        Utils.setTitle(this.authService.user.active_register.register.role.slug)
      );
    }
  }

  ngOnInit(): void {
    this.isShareholder = this.router.url.includes('shareholder');
    this.isProvider = this.router.url.includes('provider');
    this.isFund =
      this.authService.user.active_register.register.role.slug.includes(
        'PJ-FI'
      );

    console.log('teste redirect 000');

    if (this.isShareholder) {
      this.steps.splice(2, 0, ...this.shareholderSteps);

      if (this.isFund) {
        // TODO - precisa deixar os steps dinâmicos
        const stepsToExclude: CompanyFormKeys[] = [
          'complementaryAddress',
          'complementaryComposition',
          'complementaryEconomic',
          'complementaryRepresentatives',
          'income',
          'conditions',
          'powers',
        ];

        this.steps = this.steps.filter((step) => {
          if (step.slug === 'complementary') {
            step.substeps = step.substeps.filter((substep) => {
              return !stepsToExclude.includes(substep.slug as CompanyFormKeys);
            });
          }

          return !stepsToExclude.includes(step.slug as CompanyFormKeys);
        });

        stepsToExclude.forEach((step) => {
          this.signupService.setCompanyFormStatus(step, true);
        });

        const analysisIndex = this.steps.findIndex(
          (step) => step.slug === 'analysis'
        );

        const taxIndex = this.steps.findIndex((step) => step.slug === 'tax');

        this.steps[taxIndex].title =
          'Declaração US Person / Non US Person (FATCA)';
        this.steps[analysisIndex].title =
          'Perfil de Investimento do Fundo Cotista';

        this.steps[0].substeps.push(
          {
            title: 'Administrador',
            slug: 'complementaryAdmin',
          },
          {
            title: 'Gestor',
            slug: 'complementaryManager',
          }
        );
      }
    }

    if (this.isProvider) {
      this.steps[0].substeps = this.steps[0].substeps.filter(
        (substep) => substep.slug !== 'complementaryBond'
      );
    }

    this.userCredentials = {
      email: this.authService.user?.email,
      name:
        this.authService.user?.first_name +
        ' ' +
        this.authService.user?.last_name,
    };

    document.onkeydown = (e) => {
      if (e.key === 'Enter') {
        this.proceed();
      }
    };

    this.signupService.changeCompanyStepEvent.subscribe((step) => {
      const aux = `complementary${
        step.charAt(0).toUpperCase() + step.slice(1)
      }` as ComplementaryCompanySteps;

      this.activeSubstep = aux;
      this.signupService.loading = false;
      this.activeStep = 0;

      this.signupService.updateUserStep('register', step);
    });

    this.signupService.changeBasicInfoEvent.subscribe((value) => {
      this.label = this.getRoleLabel(this.role);

      this.name = value.toUpperCase();
    });

    this.signupService.fetchDataEvent.emit();

    let urlParts = this.router.url.split('/pj')[0].split('/');
    let range = urlParts.length;
    this.role = urlParts[range - 1];

    if (this.role === 'assignor') {
      // TODO mudar a logica - replace representative step title
      this.steps[0].substeps[6].title =
        'Representantes e Avalistas/Dev. Solidários';
    }

    this.getData();
  }

  async getData() {
    console.log('teste redirect 00');
    await this.getAgents();
    await Promise.all([
      this.getAccounts(),
      this.setInviteInfo(),
      this.redirectUser(),
    ]);
    this.redirected = true;
  }

  getUserInitials(string) {
    return string
      .toUpperCase()
      .replace(/[^\w\s]/gi, '')
      .split(' ')
      .slice(0, 2)
      .map((word: string) => word.charAt(0))
      .join('');
  }

  async getAgents() {
    try {
      const res = await this.apiService.get({
        route: 'api/registration/simplified-agent/',
      });

      this.agentOptions = res.map((item: any) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    } catch (error) {
      console.warn(error);
    }
  }

  async redirectToDash() {
    // precisa-se desses requests pois se o cadastro não foi finalizado,
    // o active_register dele vai faltar muitos dados.. assim crashando o app
    const res = await this.apiService.patch({
      route: `user/me/`,
      token: true,
      body: {
        active_register: this.accounts[0].id,
      },
    });

    const { data } = await this.apiService.get<ApiResponse<NewLoginResponse>>({
      route: 'user/me/',
      token: true,
    });

    this.authService.setUser(data);

    this.router.navigateByUrl('/app/registers');
  }

  async getAccounts() {
    try {
      const data = await this.apiService.get<RegisterUserResponse[]>({
        route: 'api/v2/registration/account-switch/',
        token: true,
        params: {
          type: 'profile',
          mode: 'list',
        },
      });

      const newData = data.sort((a, b) => b.id - a.id).reverse();
      this.accounts = newData;

      this.hasMultipleAccounts = data.length > 1;
    } catch (error) {
      console.error(error);
    }
  }

  async redirectUser() {
    const user = this.authService.getUser();

    console.log('teste r 0', user);

    const { data } = await this.apiService.get({
      route: 'api/registration/trace_step/me/',
      token: true,
    });

    console.log('teste r 1', data);
    this.signupService.setComplementaryCompanyStep(data.substep);

    this.setBasicInfo();

    // TODO mudar essa lógica

    if (data.step === 'register') {
      if (this.isShareholder) {
        if (this.isFund) {
          switch (data.substep) {
            case 'declarations':
              this.activeStep = 1;
              break;
            case 'analysis':
              this.activeStep = 2;
              break;
            case 'tax':
              this.activeStep = 3;
              break;
            case 'attachments':
              this.activeStep = 4;
              break;
            default:
              this.activeSubstep = `complementary${
                data.substep.charAt(0).toUpperCase() + data.substep.slice(1)
              }` as ComplementaryCompanySteps;
              this.signupService.setComplementaryCompanyStep(
                this.activeSubstep
              );
              this.activeStep = 0;
              break;
          }
        } else {
          switch (data.substep) {
            case 'powers':
              this.activeStep = 1;
              break;
            case 'income':
              this.activeStep = 2;
              break;
            case 'declarations':
              this.activeStep = 3;
              break;
            case 'analysis':
              this.activeStep = 4;
              break;
            case 'conditions':
              this.activeStep = 5;
              break;
            case 'tax':
              this.activeStep = 6;
              break;
            case 'attachments':
              this.activeStep = 7;
              break;
            default:
              this.activeSubstep = `complementary${
                data.substep.charAt(0).toUpperCase() + data.substep.slice(1)
              }` as ComplementaryCompanySteps;
              this.signupService.setComplementaryCompanyStep(
                this.activeSubstep
              );
              this.activeStep = 0;
              break;
          }
        }
      } else {
        if (data.substep === 'powers') {
          this.activeStep = 1;
        } else if (data.substep === 'attachments') {
          this.activeStep = 2;
        } else {
          console.log(
            'teste r 2',
            `complementary${
              data.substep.charAt(0).toUpperCase() + data.substep.slice(1)
            }`
          );
          this.activeSubstep = `complementary${
            data.substep.charAt(0).toUpperCase() + data.substep.slice(1)
          }` as ComplementaryCompanySteps;
          this.signupService.setComplementaryCompanyStep(this.activeSubstep);
          this.activeStep = 0;
        }
      }
    }

    if (data.step === 'review') {
      this.router.navigateByUrl(
        '/status/pj?company=' +
          (user.active_register?.register?.company ?? user.id)
      );
    }

    if (data.step !== 'register' && data.step !== 'review') {
      this.router.navigateByUrl(`/app/dashboard`);
    }
  }

  async setBasicInfo() {
    try {
      const { data } = await this.apiService.get<ApiResponse<Company>>({
        route: 'api/registration/pj_general/me/',
        token: true,
      });

      if (data.corporate_name !== null && data.corporate_name !== '') {
        this.label = this.getRoleLabel(this.role);

        this.name = data.corporate_name.toUpperCase();
      }
    } catch (error) {
      console.log(error);
    }
  }

  getRoleLabel(role: string) {
    const user = this.authService.getUser();

    switch (role) {
      case 'assignor':
        return 'Cedente Pessoa Jurídica';

      case 'provider':
        let label = '';

        if (user.active_register.register.invite) {
          label = user.active_register.register.invite.data.agent
            .map((item) => {
              return this.agentOptions.find((agent) => agent.value === item)
                .label;
            })
            .join(', ');
        } else {
          label = user.active_register.register.agent
            .map((item) => item.name)
            .join(', ');
        }

        return label;

      default:
        return `Cotista ${user.active_register.register?.role?.name ?? ''}`;
    }
  }

  async sendApproval() {
    try {
      await this.apiService.post({
        route: 'api/approvals/user_approval/',
        token: true,
      });

      this.toastService.show(
        'info',
        'Sucesso',
        'Solicitação de aprovação enviada com sucesso'
      );

      return true;
    } catch (error) {
      this.toastService.show(
        'error',
        'Erro',
        'Falha ao enviar solicitação de aprovação ao sistema'
      );

      this.activeStep--;

      return false;
    }
  }

  canProceed() {
    // TODO mudar essa lógica

    if (this.isShareholder) {
      if (this.isFund) {
        switch (this.activeStep) {
          case 0:
            const step = this.signupService.getComplementaryCompanyStep();
            return this.signupService.getCompanyFormStatus(step);

          case 1:
            const declarations =
              this.signupService.getCompanyFormStatus('declarations');
            return declarations;

          case 2:
            const analysis =
              this.signupService.getCompanyFormStatus('analysis');
            return analysis;

          case 3:
            const tax = this.signupService.getCompanyFormStatus('tax');
            return tax;

          case 4:
            const attachment =
              this.signupService.getCompanyFormStatus('attachments');
            return attachment;

          case 5:
            return true;
          default:
            return false;
        }
      } else {
        switch (this.activeStep) {
          case 0:
            const step = this.signupService.getComplementaryCompanyStep();
            return this.signupService.getCompanyFormStatus(step);

          case 1:
            const powers = this.signupService.getCompanyFormStatus('powers');
            return powers;

          case 2:
            const realy =
              this.signupService.getCompanyFormStatus('incomeRealty');
            const others =
              this.signupService.getCompanyFormStatus('incomeOthers');
            const income =
              this.signupService.getCompanyFormStatus('incomeSalary');

            return realy && income && others;

          case 3:
            const declarations =
              this.signupService.getCompanyFormStatus('declarations');
            return declarations;

          case 4:
            const analysis =
              this.signupService.getCompanyFormStatus('analysis');
            return analysis;

          case 5:
            const conditions =
              this.signupService.getCompanyFormStatus('conditions');
            return conditions;

          case 6:
            const tax = this.signupService.getCompanyFormStatus('tax');
            return tax;

          case 7:
            const attachment =
              this.signupService.getCompanyFormStatus('attachments');
            return attachment;

          case 8:
            return true;
          default:
            return false;
        }
      }
    } else {
      switch (this.activeStep) {
        case 0:
          const step = this.signupService.getComplementaryCompanyStep();
          return this.signupService.getCompanyFormStatus(step);

        case 1:
          const powers = this.signupService.getCompanyFormStatus('powers');
          return powers;

        case 2:
          const attachment =
            this.signupService.getCompanyFormStatus('attachments');
          return attachment;

        case 3:
          return true;
        default:
          return false;
      }
    }
  }

  getButtonIcon(stepAction: string) {
    if (stepAction === 'next') {
      return this.canProceed()
        ? 'assets/images/icon_button_arrow.svg'
        : 'assets/images/icon_button_block.svg';
    } else {
      return this.activeStep === 0
        ? 'assets/images/icon_button_block.svg'
        : 'assets/images/icon_button_arrow.svg';
    }
  }

  toggleUserOptions() {
    this.userOptionsOpen = !this.userOptionsOpen;
  }

  setActiveStep(event: any) {
    this.activeStep = event;
  }

  runBack() {
    // TODO mudar essa lógica
    if (this.isShareholder) {
      if (this.isFund) {
        switch (this.activeStep) {
          case 4:
            this.activeStep--;

            this.signupService.updateUserStep('register', 'tax');
            break;

          case 3:
            this.activeStep--;

            this.signupService.updateUserStep('register', 'analysis');
            break;

          case 2:
            this.activeStep--;

            this.signupService.updateUserStep('register', 'declarations');
            break;

          case 1:
            this.activeStep--;

            this.signupService.changeCompanyStepEvent.emit('manager');

            this.signupService.setComplementaryCompanyStep(
              'complementaryManager'
            );

            break;

          case 0:
            switch (this.activeSubstep) {
              case 'complementaryBank':
                this.signupService.changeCompanyStepEvent.emit('general');

                this.signupService.setComplementaryCompanyStep(
                  'complementaryGeneral'
                );
                break;

              case 'complementaryBond':
                this.signupService.changeCompanyStepEvent.emit('bank');

                this.signupService.setComplementaryCompanyStep(
                  'complementaryBank'
                );
                break;

              case 'complementaryAdmin':
                this.signupService.changeCompanyStepEvent.emit('bond');

                this.signupService.setComplementaryCompanyStep(
                  'complementaryBond'
                );
                break;

              case 'complementaryManager':
                this.signupService.changeCompanyStepEvent.emit('admin');

                this.signupService.setComplementaryCompanyStep(
                  'complementaryAdmin'
                );
                break;

              default:
                break;
            }

            break;
          default:
            break;
        }
      } else {
        switch (this.activeStep) {
          case 7:
            this.activeStep--;

            this.signupService.updateUserStep('register', 'tax');
            break;

          case 6:
            this.activeStep--;

            this.signupService.updateUserStep('register', 'conditions');
            break;

          case 5:
            this.activeStep--;

            this.signupService.updateUserStep('register', 'analysis');
            break;

          case 4:
            this.activeStep--;

            this.signupService.updateUserStep('register', 'declarations');
            break;

          case 3:
            this.activeStep--;

            this.signupService.updateUserStep('register', 'income');
            break;

          case 2:
            this.activeStep--;

            this.signupService.updateUserStep('register', 'powers');
            break;

          case 1:
            this.activeStep--;

            this.signupService.changeCompanyStepEvent.emit('representatives');

            this.signupService.setComplementaryCompanyStep(
              'complementaryRepresentatives'
            );

            break;

          case 0:
            switch (this.activeSubstep) {
              case 'complementaryAddress':
                this.signupService.changeCompanyStepEvent.emit('general');

                this.signupService.setComplementaryCompanyStep(
                  'complementaryGeneral'
                );
                break;

              case 'complementaryBank':
                this.signupService.changeCompanyStepEvent.emit('address');

                this.signupService.setComplementaryCompanyStep(
                  'complementaryAddress'
                );
                break;

              case 'complementaryComposition':
                this.signupService.changeCompanyStepEvent.emit('bank');

                this.signupService.setComplementaryCompanyStep(
                  'complementaryBank'
                );
                break;

              case 'complementaryEconomic':
                this.signupService.changeCompanyStepEvent.emit('composition');

                this.signupService.setComplementaryCompanyStep(
                  'complementaryComposition'
                );
                break;

              case 'complementaryBond':
                this.signupService.changeCompanyStepEvent.emit('economic');

                this.signupService.setComplementaryCompanyStep(
                  'complementaryEconomic'
                );
                break;

              case 'complementaryRepresentatives':
                this.signupService.changeCompanyStepEvent.emit('bond');

                this.signupService.setComplementaryCompanyStep(
                  'complementaryBond'
                );
                break;

              case 'complementaryAdmin':
                this.signupService.changeCompanyStepEvent.emit(
                  'representatives'
                );

                this.signupService.setComplementaryCompanyStep(
                  'complementaryRepresentatives'
                );
                break;

              case 'complementaryManager':
                this.signupService.changeCompanyStepEvent.emit('admin');

                this.signupService.setComplementaryCompanyStep(
                  'complementaryAdmin'
                );
                break;

              default:
                break;
            }

            break;
          default:
            break;
        }
      }
    } else {
      if (this.isProvider) {
        switch (this.activeStep) {
          case 2:
            this.activeStep--;

            this.signupService.updateUserStep('register', 'powers');
            break;

          case 1:
            this.activeStep--;

            this.signupService.changeCompanyStepEvent.emit('representatives');

            this.signupService.setComplementaryCompanyStep(
              'complementaryRepresentatives'
            );
            break;

          case 0:
            switch (this.activeSubstep) {
              case 'complementaryAddress':
                this.signupService.changeCompanyStepEvent.emit('general');

                this.signupService.setComplementaryCompanyStep(
                  'complementaryGeneral'
                );
                break;

              case 'complementaryBank':
                this.signupService.changeCompanyStepEvent.emit('address');

                this.signupService.setComplementaryCompanyStep(
                  'complementaryAddress'
                );
                break;

              case 'complementaryComposition':
                this.signupService.changeCompanyStepEvent.emit('bank');

                this.signupService.setComplementaryCompanyStep(
                  'complementaryBank'
                );
                break;

              case 'complementaryEconomic':
                this.signupService.changeCompanyStepEvent.emit('composition');

                this.signupService.setComplementaryCompanyStep(
                  'complementaryComposition'
                );
                break;

              case 'complementaryRepresentatives':
                this.signupService.changeCompanyStepEvent.emit('economic');

                this.signupService.setComplementaryCompanyStep(
                  'complementaryEconomic'
                );
                break;

              default:
                break;
            }

            break;
          default:
            break;
        }
      } else {
        switch (this.activeStep) {
          case 2:
            this.activeStep--;

            this.signupService.updateUserStep('register', 'powers');
            break;

          case 1:
            this.activeStep--;

            this.signupService.changeCompanyStepEvent.emit('representatives');

            this.signupService.setComplementaryCompanyStep(
              'complementaryRepresentatives'
            );
            break;

          case 0:
            switch (this.activeSubstep) {
              case 'complementaryAddress':
                this.signupService.changeCompanyStepEvent.emit('general');

                this.signupService.setComplementaryCompanyStep(
                  'complementaryGeneral'
                );
                break;

              case 'complementaryBank':
                this.signupService.changeCompanyStepEvent.emit('address');

                this.signupService.setComplementaryCompanyStep(
                  'complementaryAddress'
                );
                break;

              case 'complementaryComposition':
                this.signupService.changeCompanyStepEvent.emit('bank');

                this.signupService.setComplementaryCompanyStep(
                  'complementaryBank'
                );
                break;

              case 'complementaryEconomic':
                this.signupService.changeCompanyStepEvent.emit('composition');

                this.signupService.setComplementaryCompanyStep(
                  'complementaryComposition'
                );
                break;

              case 'complementaryBond':
                this.signupService.changeCompanyStepEvent.emit('economic');

                this.signupService.setComplementaryCompanyStep(
                  'complementaryEconomic'
                );
                break;

              case 'complementaryRepresentatives':
                this.signupService.changeCompanyStepEvent.emit('bond');

                this.signupService.setComplementaryCompanyStep(
                  'complementaryBond'
                );
                break;

              default:
                break;
            }

            break;
          default:
            break;
        }
      }
    }
  }

  async proceed() {
    this.signupService.loading = true;

    // TODO mudar essa lógica

    if (this.canProceed()) {
      if (this.isShareholder) {
        if (this.isFund) {
          switch (this.activeStep) {
            case 0:
              const step = this.signupService.getComplementaryCompanyStep();

              if (step !== 'complementaryManager') {
                this.signupService.sendComplementaryCompanyData.emit(step);
                return;
              }

              this.signupService.updateUserStep('register', 'declarations');
              this.activeStep++;
              break;

            case 1:
              this.signupService.sendCompanyData.emit('declarations');
              this.signupService.updateUserStep('register', 'analysis');
              this.activeStep++;
              break;

            case 2:
              this.signupService.sendCompanyData.emit('analysis');
              this.signupService.updateUserStep('register', 'tax');
              this.activeStep++;
              break;

            case 3:
              this.signupService.sendCompanyData.emit('tax');
              this.signupService.updateUserStep('register', 'attachments');
              this.activeStep++;
              break;

            case 4:
              this.activeStep++;

              const approvalDone = await this.sendApproval();
              if (approvalDone) {
                const { data } = await this.apiService.get<
                  ApiResponse<Company>
                >({
                  route: 'api/registration/pj_general/me/',
                  token: true,
                });

                this.router.navigateByUrl(`/status/pj?company=${data.id}`);
              }

              break;

            case 5:
              this.router.navigate([`/app/dashboard`]);
              break;
          }
        } else {
          switch (this.activeStep) {
            case 0:
              const step = this.signupService.getComplementaryCompanyStep();

              if (step !== 'complementaryRepresentatives') {
                this.signupService.sendComplementaryCompanyData.emit(step);
                return;
              }

              this.signupService.updateUserStep('register', 'powers');
              this.activeStep++;

              break;

            case 1:
              this.signupService.sendCompanyData.emit('powers');
              this.signupService.updateUserStep('register', 'income');
              this.activeStep++;
              break;

            case 2:
              this.signupService.sendCompanyData.emit('income');
              this.signupService.updateUserStep('register', 'declarations');
              this.activeStep++;
              break;

            case 3:
              this.signupService.sendCompanyData.emit('declarations');
              this.signupService.updateUserStep('register', 'analysis');
              this.activeStep++;
              break;

            case 4:
              this.signupService.sendCompanyData.emit('analysis');
              this.signupService.updateUserStep('register', 'conditions');
              this.activeStep++;
              break;

            case 5:
              this.signupService.sendCompanyData.emit('conditions');
              this.signupService.updateUserStep('register', 'tax');
              this.activeStep++;
              break;

            case 6:
              this.signupService.sendCompanyData.emit('tax');
              this.signupService.updateUserStep('register', 'attachments');
              this.activeStep++;
              break;

            case 7:
              this.activeStep++;

              const approvalDone = await this.sendApproval();
              if (approvalDone) {
                const { data } = await this.apiService.get<
                  ApiResponse<Company>
                >({
                  route: 'api/registration/pj_general/me/',
                  token: true,
                });

                this.router.navigateByUrl(`/status/pj?company=${data.id}`);
              }

              break;

            case 8:
              this.router.navigate([`/app/dashboard`]);
              break;
          }
        }
      } else {
        switch (this.activeStep) {
          case 0:
            const step = this.signupService.getComplementaryCompanyStep();

            if (step !== 'complementaryRepresentatives') {
              this.signupService.sendComplementaryCompanyData.emit(step);
              return;
            }

            this.signupService.updateUserStep('register', 'powers');
            this.activeStep++;

            break;

          case 1:
            this.signupService.updateUserStep('register', 'attachments');
            this.activeStep++;
            break;

          case 2:
            this.activeStep++;

            const approvalDone = await this.sendApproval();
            if (approvalDone) {
              const { data } = await this.apiService.get<ApiResponse<Company>>({
                route: 'api/registration/pj_general/me/',
                token: true,
              });
              this.router.navigateByUrl(`/status/pj?company=${data.id}`);
            }

            break;

          case 3:
            this.router.navigate([`/app/dashboard`]);
            break;
        }
      }
    }
    this.signupService.loading = false;
  }

  setInviteInfo() {
    const user = this.authService.getUser();
    const invite = user.active_register.register.invite;

    if (!invite) return;

    this.label = this.getRoleLabel(this.role);

    this.name = invite.data.name ? invite.data.name.toUpperCase() : '';

    this.signupService.basicInfo = {
      name: invite.data.name,
      email: invite.data.email,
      document: invite.data.document,
    };
  }

  getDisabled() {
    return !this.canProceed() || this.signupService.loading;
  }

  toggleSupportModal() {
    this.showSupportModal = !this.showSupportModal;
  }

  logoff() {
    this.authService.clearUser();

    this.router.navigate(['/login']);
  }
}
