import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { AgentsService } from 'src/app/services/agents/agents.service';
import { ApiService } from 'src/app/services/api/api.service';
import { SignupService } from 'src/app/services/signup/signup.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import Swal from 'sweetalert2';
import fakerbr from 'faker-br';
import Utils from 'src/app/helpers/utils';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-agents',
  templateUrl: './dashboard-agents.component.html',
  styleUrls: ['./dashboard-agents.component.scss'],
})
export class DashboardAgentsComponent implements OnInit {
  agentsForm = this.formBuilder.group({
    name: [null, [Validators.required]],
  });

  permissionObj: PermissionValues = {
    canAdd: false,
    canChange: false,
    canDelete: false,
  };

  addAgents = false;

  agents: any[] = [];
  agent: any = undefined;

  filteredData = this.agents;

  loading = false;

  constructor(
    private formBuilder: FormBuilder,
    private api: ApiService,
    private toast: ToastService,
    private agentsService: AgentsService,
    private signupService: SignupService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.authService.permissionsLoadedEvent.subscribe(() => {
      this.authService.canViewPage(['de_can_view_agents']);
    });

    this.getRoles();
    this.setData();
    this.setPermissions();
  }

  async getRoles() {
    this.loading = true;

    try {
      const res = await this.api.get({
        route: 'api/registration/agent/',
        token: true,
      });

      this.agents = res.map((item: any) => {
        return {
          ...item,
          label: item.name,
          value: item.id,
        };
      });

      this.filteredData = this.agents;

      this.agentsService.data = this.agents;
      this.agentsService.onChange.emit();
    } catch (error) {
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }

    this.loading = false;
  }

  addAgentsToggle(status: boolean) {
    this.addAgents = status;

    if (!status) this.agent = undefined;
  }

  async addAgent() {
    try {
      const values = this.agentsForm.value;

      const payload = {
        name: values.name,
      };

      const alreadyHaveAgent = this.agents.find(
        (_agent) => _agent.name === payload.name
      );

      if (alreadyHaveAgent) {
        this.toast.show('error', 'Erro', 'Já existe um agente com esse nome.');
        return;
      }

      const res = this.agent
        ? await this.api.put({
            route: `api/registration/agent/${this.agent.value}/`,
            token: true,
            body: payload,
          })
        : await this.api.post({
            route: 'api/registration/agent/',
            token: true,
            body: payload,
          });

      this.toast.show('info', 'Sucesso', 'Agente cadastrado com sucesso.');

      this.addAgentsToggle(false);

      this.getRoles();

      this.agent = undefined;
      this.agentsForm.reset();
    } catch (error) {
      this.toast.show(
        'error',
        'Erro',
        'Ocorreu um erro ao cadastrar o agente.'
      );
    }
  }

  setData() {
    this.signupService.fillFormEvent.subscribe(() => {
      this.agentsForm.patchValue({
        name: fakerbr.name.findName(),
        slug: Utils.convertToSlug(fakerbr.name.findName()),
        description: fakerbr.random.words(3),
        applicable: 'admin',
        is_visible: true,
      });
    });
  }

  setAgent(agent: any) {
    this.agentsForm.patchValue({
      name: agent.label,
    });
    this.agent = agent;
    this.addAgentsToggle(true);
  }

  async removeAgent(id: number) {
    const { isConfirmed } = await Swal.fire({
      title: 'Tem certeza?',
      text: 'Você não poderá reverter isso!',
      icon: 'warning',
      showConfirmButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sim, apague!',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
    });

    if (isConfirmed) {
      const options: { [Key in any]: any } = {};

      this.agents
        .filter((_agent) => _agent.id !== id)
        .forEach((_agent) => {
          options[_agent.id] = _agent.name;
        });

      await Swal.fire({
        title: 'Atenção',
        text: 'Antes de continuar, selecione um papel para migrar os usuários',
        icon: 'warning',
        input: 'select',
        inputOptions: options,
        showConfirmButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Prosseguir!',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',

        showLoaderOnConfirm: true,

        preConfirm: (role) => {
          return this.api
            .delete({
              route: 'api/roles',
              token: true,
              params: {
                id,
                role,
              },
            })
            .then((res) => {
              this.agents = this.agents.filter((agent) => agent.id !== id);

              Swal.fire({
                title: 'Apagado!',
                text: 'Agente apagado com sucesso',
                icon: 'success',
                confirmButtonColor: '#3085d6',
              });
            })
            .catch((error) => {
              Swal.fire({
                title: 'Erro!',
                text: 'Erro ao apagar agente',
                icon: 'error',
                confirmButtonColor: '#3085d6',
              });
            });
        },
      });
    }
  }

  //   async updateAgent() {
  //     Swal.fire({
  //       title: 'Tem certeza?',
  //       text: 'Os dados serão atualizados!',
  //       icon: 'warning',
  //       showCancelButton: true,
  //       confirmButtonColor: '#3085d6',
  //       cancelButtonColor: '#d33',
  //       confirmButtonText: 'Sim, atualizar!',
  //       cancelButtonText: 'Cancelar',
  //       preConfirm: () => {
  //         const values = this.agentsForm.value;

  //         return this.api
  //           .put({
  //             route: 'api/roles',
  //             token: true,
  //             body: values,
  //             params: { id: this.agent.id },
  //           })
  //           .then((res) => {
  //             const agentIdx = this.agents.findIndex(
  //               (agent) => agent.id === this.agent.id
  //             );

  //             this.agents[agentIdx] = res;

  //             Swal.fire(
  //               'Atualizado!',
  //               'Agente atualizado com sucesso',
  //               'success'
  //             );

  //             this.addAgentsToggle(false);
  //             this.agentsForm.reset();
  //           })
  //           .catch((error) => {
  //             Swal.fire('Erro!', 'Erro ao atualizar agente', 'error');
  //           });
  //       },
  //     });
  //   }

  setPermissions() {
    const permissions = Object.keys(this.permissionObj).map(
      (key) => `de_can_${key.split('can').join('').toLowerCase()}_agents`
    );

    permissions.forEach((permission, idx) => {
      this.permissionObj[Object.keys(this.permissionObj)[idx]] =
        this.authService.verifyPermission(permission);
    });
  }
}
