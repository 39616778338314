import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api/api.service';
import { SignupService } from 'src/app/services/signup/signup.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import Swal from 'sweetalert2';
import fakerbr from 'faker-br';

import { distinctUntilChanged } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-documents',
  templateUrl: './dashboard-documents.component.html',
  styleUrls: ['./dashboard-documents.component.scss'],
})
export class DashboardDocumentsComponent implements OnInit {
  form = this.formBuilder.group({
    name: [null, [Validators.required, Validators.maxLength(30)]],
    description: [null, [Validators.required, Validators.maxLength(210)]],
    roles: [null, [Validators.required]],
    frameworks: [null, [Validators.required]],
    required: [false, []],
    have_due_date: [false, []],
    individual: [false, []],
    show_in: [null, []],
  });

  permissionObj: PermissionValues = {
    canAdd: false,
    canChange: false,
    canDelete: false,
  };

  modal = false;

  documents: any[] = [];
  document: any = undefined;

  filteredData = this.documents;

  loading = true;

  perPage = 50;
  currentPage = 1;
  pages = [1];

  agentsOptions: any[] = [];
  frameworkList: SelectItem[] = [];
  cardsList: SelectItem[] = [];

  showFrameworks = false;

  disableShowIn = false;
  disableIndividual = false;

  names: { [Key: string]: string } = {
    ALL: 'Todos',
    ADM: 'Administração',
    ASSIGNOR: 'Cedente',
    SHAREHOLDER: 'Cotista',
    PROVIDER: 'Prestador de serviço',
    GUARANTOR: 'Avalista',
    REPRESENTATIVE: 'Representante',
  };

  constructor(
    private formBuilder: FormBuilder,
    private api: ApiService,
    private toast: ToastService,
    public signupService: SignupService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.authService.permissionsLoadedEvent.subscribe(() => {
      this.authService.canViewPage(['de_can_view_documents']);
    });

    this.getData();
    this.setData();
    this.getFrameworks();
    this.getCards();
    this.setPermissions();

    this.form.controls.individual.valueChanges
      .pipe(distinctUntilChanged((a: any, b: any) => a === b))
      .subscribe((value) => {
        this.form.controls.show_in.setValue(null);

        if (value) {
          this.disableShowIn = true;
        } else {
          this.disableShowIn = false;
        }

        this.form.controls.show_in.updateValueAndValidity();
      });

    this.form.controls.show_in.valueChanges.subscribe((value) => {
      if (value && value.length > 0) {
        this.form.controls.individual.setValue(false);
        this.disableIndividual = true;
      } else {
        this.disableIndividual = false;
      }

      this.form.controls.individual.updateValueAndValidity();
    });

    this.form.controls.roles.valueChanges.subscribe((value: any) => {
      let roles: any[] = [];

      if (value) {
        roles = value
          .map(
            (item: any) =>
              this.agentsOptions.find((i) => i.value === item)?.extra
          )
          .join(', ');
      }
      if (roles.includes('PJ')) {
        this.form.controls.frameworks.setValidators([Validators.required]);
        this.showFrameworks = true;
      } else {
        this.form.controls.frameworks.clearValidators();
        this.form.controls.frameworks.setValue(null);
        this.showFrameworks = false;
      }
    });
  }

  async getCards() {
    try {
      const { data } = await this.api.get({
        route: 'api/registration/frontend_cards/',
        token: true,
      });

      if (data) {
        this.cardsList = data.map((item: any) => {
          return {
            label: item.name,
            value: item.id,
          };
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getFrameworks() {
    try {
      const res = await this.api.get({
        route: 'api/registration/framework',
        token: true,
      });

      if (res) {
        this.frameworkList = res.map((item: any) => {
          return {
            label: `${item.name} ${item.initials}`,
            value: item.id,
          };
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getData() {
    await Promise.all([this.getRequestFiles(), this.getRoles()]);
  }

  async getRoles() {
    try {
      const res = await this.api.get({
        route: 'api/roles',
        token: true,
      });

      const newAgentsOptions = res.map((_agent: any) => {
        const roles = _agent.slug.split('-');

        if (roles[0].includes('ADM') || roles[0].includes('REPRESENTATIVE')) {
          return undefined;
        }
        return {
          label: `${this.names[roles[0]]} ${_agent.name}`,
          value: _agent.id,
          extra: roles.includes('PJ') ? 'PJ' : 'PF',
        };
      });

      this.agentsOptions = newAgentsOptions.filter(
        (agent: any) => agent !== undefined
      );
    } catch (error) {
      console.warn(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }
  }

  async getRequestFiles() {
    try {
      const res = await this.api.get({
        route: 'api/registration/file_type/',
        token: true,
      });

      const documents: { [Key: string]: any } = {};

      res.forEach((item: any) => {
        if (documents.hasOwnProperty(item.id)) {
          documents[item.id].role_names.push(item.roles.name);
        } else {
          documents[item.id] = {
            id: item.id,
            name: item.name,
            description: item.description,
            roles: item.roles,
            role_names: [item.roles.name],
            required: item.required,
            have_due_date: item.have_due_date,
            individual: item.individual,
            show_in: item.show_in,
            frameworks: item.frameworks,
          };
        }
      });

      this.documents = Object.values(documents);

      this.loading = false;
    } catch (error) {
      console.warn(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }
  }

  isAdminField(slug: string) {
    if (slug) {
      return slug.includes('ADM');
    }
    return false;
  }

  paginateData() {
    const filtered = this.documents.filter(
      (_, i) =>
        i >= this.perPage * (this.currentPage - 1) &&
        i < this.perPage * this.currentPage
    );

    const pageNumber = Math.ceil(this.documents.length / this.perPage);

    const pages = [];

    for (let i = 1; i <= pageNumber; i++) {
      pages.push(i);
    }

    this.pages = pages;
    this.filteredData = filtered;
  }

  formToggle(status: boolean) {
    this.modal = status;
  }

  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;

      this.paginateData();
    }
  }

  closeModal() {
    this.form.reset();
    this.document = null;
    this.formToggle(false);
  }

  nextPage() {
    if (this.currentPage < this.pages.length) {
      this.currentPage++;

      this.paginateData();
    }
  }

  changePage(page: number) {
    this.currentPage = page;

    this.paginateData();
  }

  setDocument(document: any) {
    this.document = document;

    this.form.patchValue({
      name: document.name,
      description: document.description,
      roles: document.roles.map((_role: any) => _role.id),
      required: document.required,
      frameworks: document.frameworks.map((_framework: any) => _framework.id),
      have_due_date: document.have_due_date,
      individual: document.individual,
      show_in: document.show_in,
    });
    this.formToggle(true);
  }

  async removeDocument(id: number) {
    await Swal.fire({
      title: 'Tem certeza?',
      text: 'Você não poderá reverter isso!',
      icon: 'warning',
      showConfirmButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sim, apague!',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.api
          .delete({
            route: `api/registration/file_type/${id}/`,
            token: true,
          })
          .then((res) => {
            this.documents = this.documents.filter(
              (document: any) => document.id !== id
            );

            Swal.fire({
              title: 'Apagado!',
              text: 'Documento apagado com sucesso',
              icon: 'success',
              confirmButtonColor: '#3085d6',
            });
          })
          .catch((error) => {
            Swal.fire({
              title: 'Erro!',
              text: 'Erro ao apagar documento',
              icon: 'error',
              confirmButtonColor: '#3085d6',
            });
          });
      },
    });
  }

  async addDocument() {
    try {
      const values = this.form.value;

      const payload = {
        roles_ids: values.roles ?? [],
        name: values.name,
        slug: values.name
          .toLowerCase()
          .replace(/[^\w\s]/gi, '')
          .split(' ')
          .join('-'),
        description: values.description,
        frameworks_ids: values.frameworks ?? [],
        required: values.required ?? false,
        have_due_date: values.have_due_date ?? false,
        individual: values.individual ?? false,
        show_in: values.show_in ?? [],
      };

      const res = this.document
        ? await this.api.put({
            route: `api/registration/file_type/${this.document.id}/`,
            token: true,
            body: payload,
          })
        : await this.api.post({
            route: 'api/registration/file_type/',
            token: true,
            body: payload,
          });

      this.toast.show('info', 'Sucesso', 'Documento cadastrado com sucesso.');

      this.document = null;

      this.formToggle(false);

      this.form.reset();

      this.getRequestFiles();
    } catch (error) {
      this.toast.show(
        'error',
        'Erro',
        'Ocorreu um erro ao cadastrar o documento.'
      );
    }
  }

  setData() {
    this.signupService.fillFormEvent.subscribe(() => {
      this.form.patchValue({
        name: fakerbr.name.findName(),
        description: fakerbr.random.words(3),
        roles: [this.agentsOptions[0].value],
        required: false,
        frameworks: [this.frameworkList[0].value],
      });
    });
  }

  formatRoles(roles: any[]) {
    if (roles.length > 0) {
      let idArray = roles.map((role: any) => role.id);
      let auxArr: any[] = [];

      this.agentsOptions.forEach((agent: any) => {
        if (idArray.includes(agent.value)) {
          auxArr.push(agent.label);
        }
      });

      return auxArr.join(', ');
    }

    return '';
  }

  setPermissions() {
    const permissions = Object.keys(this.permissionObj).map(
      (key) => `de_can_${key.split('can').join('').toLowerCase()}_documents`
    );

    permissions.forEach((permission, idx) => {
      this.permissionObj[Object.keys(this.permissionObj)[idx]] =
        this.authService.verifyPermission(permission);
    });
  }
}
