import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { ApiService } from 'src/app/services/api/api.service';

type PermissionProps = {
  label: string;
  value: number;
};

type PermissionResponse = {
  description: string;
  id: number;
  slug: string;
  type: string;
};

@Component({
  selector: 'app-dashboard-permissions-register',
  templateUrl: './dashboard-permissions-register.component.html',
  styleUrls: ['./dashboard-permissions-register.component.scss'],
})
export class DashboardPermissionsRegisterComponent implements OnInit {
  label: string = 'Adicionar conjunto de permissões';

  loading: boolean = true;

  form = this.formBuilder.group({
    name: [null, Validators.required],
    active: [true],
  });

  searchForm = this.formBuilder.group({
    avaliablePermissions: [null],
    selectedPermissions: [null],
  });

  user: NewLoginResponse | undefined;

  avaliableOrderAsc = true;
  selectedOrderAsc = true;
  isEditing = false;
  createdBy: string = '';
  permissions: SelectItem[] = [];

  totalAvaliableOptions = 0;
  avaliableCheckedNumber = 0;
  totalSelectedOptions = 0;
  selectedCheckedNumber = 0;

  permissionsSelectedPicked: PermissionProps[] = [];
  permissionsAvaliablePicked: PermissionProps[] = [];

  permissionsAvaliable: PermissionProps[] = [];
  permissionsSelected: PermissionProps[] = [];
  visiblePermissionsSelected: PermissionProps[] = [];

  sendingRequest: boolean = false;

  constructor(
    public route: Router,
    public formBuilder: FormBuilder,
    private authService: AuthService,
    private toast: ToastService,
    private api: ApiService
  ) {}

  ngOnInit(): void {
    if (
      !this.authService.verifyPermission(['de_can_add_group_permission']) &&
      !this.authService.verifyPermission(['de_can_change_group_permission'])
    ) {
      this.toast.show(
        'error',
        'Aviso',
        'Você não tem permissão para acessar essa página.'
      );
      this.route.navigateByUrl('/app/dashboard');
    }

    this.isEditing = this.route.url.includes('update');

    this.getData();

    this.user = this.authService.getUser();

    this.label = this.route.url.includes('update')
      ? 'Atualizar conjunto de permissões'
      : 'Adicionar conjunto de permissões';

    this.searchForm.controls.avaliablePermissions.valueChanges.subscribe(
      async (value) => {
        this.permissionsAvaliablePicked = [];
        await this.getPermissionsOptions(value);

        this.filterPermissions();
        this.handleSetNumbers(true);
      }
    );

    this.searchForm.controls.selectedPermissions.valueChanges.subscribe(
      (value) => {
        this.permissionsSelectedPicked = [];

        this.visiblePermissionsSelected = this.permissionsSelected.filter(
          (item) => item.label.toLowerCase().includes(value.toLowerCase())
        );
        this.handleSetNumbers();
      }
    );
  }

  handleCheckAll(isAvaliable: boolean = false) {
    if (isAvaliable) {
      const valuesMissingInPicked = this.permissionsAvaliable.filter(
        (item) =>
          !this.permissionsAvaliablePicked.some(
            (picked) => picked.value === item.value
          )
      );

      if (valuesMissingInPicked.length === 0) {
        this.permissionsAvaliablePicked = [];
        this.handleSetNumbers();
        return;
      }

      valuesMissingInPicked.forEach((item) => {
        this.permissionsAvaliablePicked.push(item);
      });
      this.handleSetNumbers();
    } else {
      const valuesMissingInPicked = this.visiblePermissionsSelected.filter(
        (item) =>
          !this.permissionsSelectedPicked.some(
            (picked) => picked.value === item.value
          )
      );

      if (valuesMissingInPicked.length === 0) {
        this.permissionsSelectedPicked = [];
        this.handleSetNumbers();
        return;
      }

      valuesMissingInPicked.forEach((item) => {
        this.permissionsSelectedPicked.push(item);
      });
      this.handleSetNumbers();
    }
  }

  verifyCheckAll(isAvaliable: boolean = false) {
    if (isAvaliable) {
      return (
        this.permissionsAvaliablePicked.length ===
        this.permissionsAvaliable.length
      );
    }
    return (
      this.permissionsSelectedPicked.length ===
      this.visiblePermissionsSelected.length
    );
  }

  filterPermissions() {
    this.permissionsAvaliable = this.permissionsAvaliable.filter((item) => {
      return !this.permissionsSelected.some(
        (selected) => selected.value === item.value
      );
    });
  }

  async getData() {
    await this.getPermissionsOptions();
    if (this.isEditing) await this.getPermissionData();

    this.handleSetNumbers(true);
    this.handleSetNumbers();
    this.loading = false;
  }

  async getPermissionData() {
    try {
      const id = this.route.url.split('/').pop();
      const res: PermissionDetailsValue = await this.api.get({
        route: `api/v2/group/${id}/`,
        token: true,
      });

      this.form.controls.name.setValue(res.group);
      this.form.controls.active.setValue(res.status === 'Ativo');
      this.createdBy = res.created_by === '' ? 'Sistema' : res.created_by;

      this.permissionsSelected = res.permissions.map(
        (item: PermissionResponse) => ({
          label: item.description + ' - ' + item.type,
          value: item.id,
        })
      );

      this.permissionsSelected.forEach((permission) => {
        this.permissionsAvaliable = this.permissionsAvaliable.filter(
          (item) => item.value !== permission.value
        );
      });

      this.visiblePermissionsSelected = this.permissionsSelected;
    } catch (error) {
      this.toast.show('error', 'Erro', 'Erro ao carregar os dados');
    }
  }

  async getPermissionsOptions(params?: string) {
    try {
      const res = await this.api.get({
        route: 'api/v2/permission/',
        token: true,
        params: {
          search: params,
        },
      });

      this.permissions = res.map((item: PermissionResponse) => ({
        label: item.description + ' - ' + item.type,
        value: item.id,
      }));

      this.permissionsAvaliable = this.permissions;
    } catch (error) {
      this.toast.show('error', 'Erro', 'Erro ao buscar permissões');
    }
  }

  async submit() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  toggleValue(item: PermissionProps, isAvaliable: boolean = false) {
    if (isAvaliable) {
      if (this.isOptionsChecked(item, true)) {
        this.permissionsAvaliablePicked =
          this.permissionsAvaliablePicked.filter(
            (permission) => permission.value !== item.value
          );
      } else {
        this.permissionsAvaliablePicked.push(item);
      }

      this.handleSetNumbers(true);
    } else {
      if (this.isOptionsChecked(item, false)) {
        this.permissionsSelectedPicked = this.permissionsSelectedPicked.filter(
          (permission) => permission.value !== item.value
        );
      } else {
        this.permissionsSelectedPicked.push(item);
      }

      this.handleSetNumbers();
    }
  }

  isOptionsChecked(item: PermissionProps, isAvaliable: boolean = false) {
    if (isAvaliable) {
      return this.permissionsAvaliablePicked.some(
        (permission) => permission.value === item.value
      );
    }
    return this.permissionsSelectedPicked.some(
      (permission) => permission.value === item.value
    );
  }

  getDisabled(isAvaliable: boolean = false) {
    return isAvaliable
      ? this.permissionsAvaliablePicked.length === 0
      : this.permissionsSelectedPicked.length === 0;
  }

  changeSide(isAvaliable: boolean = false) {
    if (isAvaliable) {
      this.permissionsAvaliablePicked.forEach((permission) => {
        this.permissionsSelected.push(permission);
        this.visiblePermissionsSelected.push(permission);

        this.permissionsAvaliable = this.permissionsAvaliable.filter(
          (item) => item.value !== permission.value
        );
      });

      //   remove repeated values from permissionsSelected

      this.permissionsSelected = this.permissionsSelected.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.value === item.value)
      );

      this.visiblePermissionsSelected = this.visiblePermissionsSelected.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.value === item.value)
      );

      // -----

      const searchFormValue =
        this.searchForm.controls.selectedPermissions.value;

      if (searchFormValue)
        this.searchForm.controls.selectedPermissions.setValue(searchFormValue);

      this.permissionsAvaliablePicked = [];
    } else {
      this.permissionsSelectedPicked.forEach((permission) => {
        // this.permissionsAvaliable.push(permission);

        this.permissionsSelected = this.permissionsSelected.filter(
          (item) => item.value !== permission.value
        );

        this.visiblePermissionsSelected =
          this.visiblePermissionsSelected.filter(
            (item) => item.value !== permission.value
          );
      });

      this.permissionsSelectedPicked = [];
      const searchFormValue =
        this.searchForm.controls.avaliablePermissions.value;
      this.searchForm.controls.avaliablePermissions.setValue(searchFormValue);
    }

    this.handleSetNumbers(true);
    this.handleSetNumbers();
  }

  getSubmitDisabled() {
    return (
      this.form.invalid ||
      this.sendingRequest ||
      this.permissionsSelected.length === 0
    );
  }

  handleSetNumbers(isAvaliable: boolean = false) {
    if (isAvaliable) {
      this.totalAvaliableOptions = this.permissionsAvaliable.length;
      this.avaliableCheckedNumber = this.permissionsAvaliablePicked.length;
    } else {
      this.totalSelectedOptions = this.visiblePermissionsSelected.length;
      this.selectedCheckedNumber = this.permissionsAvaliablePicked.length;
    }
  }

  async handleAddPermission() {
    this.sendingRequest = true;
    try {
      const payload = {
        name: this.form.controls.name.value,
        permissions: this.permissionsSelected.map(
          (permission) => permission.value
        ),
        status: this.form.controls.active.value,
      };

      this.isEditing
        ? await this.api.put({
            route: `api/v2/group/${this.route.url.split('/').pop()}/`,
            body: payload,
            token: true,
          })
        : await this.api.post({
            route: 'api/v2/group/',
            body: payload,
            token: true,
          });

      this.toast.show('info', 'Sucesso', 'Permissão adicionada com sucesso');
      this.route.navigateByUrl('app/settings/permissions');
    } catch (error) {
      this.toast.show('error', 'Erro', 'Erro ao adicionar permissão');
    }

    this.sendingRequest = false;
  }

  handleCancel() {
    this.route.navigateByUrl('app/settings/permissions');
  }
}
