<div class="row align-items-start">
    <div class="col-lg-6" *ngIf="canView.department">
        <app-dashboard-contributors-departments [departments]="departments"
            [loading]="departmentLoading"
            (updateDepartments)="getDepartments()">
        </app-dashboard-contributors-departments>
        <app-spacer [bottom]="16"></app-spacer>
    </div>

    <div class="col-lg-6" *ngIf="canView.witnesses">
        <app-dashboard-contributors-witnesses>
        </app-dashboard-contributors-witnesses>
    </div>

    <div class="col-lg-6" *ngIf="canView.collaborator">
        <app-dashboard-contributors-collaborators [departments]="departments">
        </app-dashboard-contributors-collaborators>

        <app-spacer [bottom]="16"></app-spacer>
    </div>
</div>