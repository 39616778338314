import Utils, { UtilsValidators } from 'src/app/helpers/utils';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { SignupService } from 'src/app/services/signup/signup.service';
import fakerbr from 'faker-br';
import { MaskPipe } from 'ngx-mask';
import DDIOptions from 'src/app/data/ddi';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { LoadingService } from 'src/app/services/Loading/loading.service';

@Component({
  selector: 'app-signup-unified-personal',
  templateUrl: './signup-unified-personal.component.html',
  styleUrls: ['./signup-unified-personal.component.scss'],
})
export class SignupUnifiedPersonalComponent implements OnInit {
  @Input() userRole: string;

  DDIOptions = DDIOptions;

  form = this.formBuilder.group({
    full_name: [null, [Validators.required]],
    nacionality: ['23', [Validators.required]],
    document: [null, [Validators.required, UtilsValidators.cpf]],
    document_type: ['CPF', []],
    document_name: [null, []],
    email: [null, [Validators.required, Validators.email]],
    phone_ddi: ['55', [Validators.required]],
    fund: [null, [Validators.required]],
    agent: [null, []],
    phone_number: [null, [Validators.required, UtilsValidators.telefone]],

    terms: [false, [Validators.requiredTrue]],
  });

  nacionalities: SelectItem[] = [{ label: 'Brasileiro', value: '1' }];

  documents: SelectItem[] = [
    { label: 'Passaporte', value: '33' },
    { label: 'Número de Identificação Fiscal (NIF)', value: 'nif' },
    { label: 'Other', value: 'other' },
  ];

  ddi: SelectItem[] = [{ label: '+55', value: '+55' }];

  name: string = '';
  isPF: boolean = false;
  role: string = '';

  haveToken: boolean = true;
  loading: boolean = true;

  haveName: boolean = false;
  haveFund: boolean = false;
  haveRole: boolean = false;
  haveBasicPFInfo: boolean = false;

  fundOptions: SelectItem[] = [];
  agentOptions: SelectItem[] = [];

  constructor(
    private formBuilder: FormBuilder,
    public signupService: SignupService,
    private maskPipe: MaskPipe,
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiService,
    private toast: ToastService,
    public loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.getFunds();
    if (this.userRole === 'provider') {
      this.getAgents();
      this.form.controls.agent.setValidators([Validators.required]);
      this.form.controls.fund.setValidators([]);
      this.form.controls.agent.updateValueAndValidity();
      this.form.controls.fund.updateValueAndValidity();
    }
    this.haveToken = this.route.snapshot.params.token ? true : false;
    this.getBasicInfo(this.route.snapshot.params.token);

    this.form.valueChanges.subscribe((values) => {
      this.signupService.setBasicFormData('personal', values);
    });

    this.form.controls.document_type.valueChanges.subscribe((value) => {
      if (value === 'other') {
        this.form.controls.document_name.setValidators([Validators.required]);
      } else {
        this.form.controls.document_name.setValidators([]);
      }
      this.form.controls.document_name.updateValueAndValidity();
    });

    this.form.controls.nacionality.valueChanges.subscribe((nacionality) => {
      if (nacionality === '23') {
        this.form.controls.document_type.setValidators([]);
      } else {
        this.form.controls.document_type.setValidators([Validators.required]);
      }

      this.form.controls.document_type.updateValueAndValidity();
    });

    this.form.statusChanges.subscribe((status) => {
      if (status !== 'VALID') {
        Utils.getErrors(this.form);
      }

      this.signupService.setBasicFormStatus('personal', status === 'VALID');
    });

    this.signupService.fillFormEvent.subscribe(() => {
      const randomFundID =
        this.fundOptions.length > 0
          ? this.fundOptions[
              Math.floor(Math.random() * this.fundOptions.length)
            ].value
          : null;

      let randomRoleIDs = [];

      if (this.userRole === 'provider') {
        randomRoleIDs = [
          this.agentOptions[
            Math.floor(Math.random() * this.agentOptions.length)
          ].value,
        ];
      }

      this.form.patchValue({
        agent: randomRoleIDs.length > 0 ? randomRoleIDs : null,
        full_name: fakerbr.name.findName(),
        email: fakerbr.internet.email(),
        nacionality: '23',
        document_type: 'CPF',
        document: this.haveBasicPFInfo
          ? this.form.controls.document.value
          : this.maskPipe.transform(fakerbr.br.cpf(), '000.000.000-00'),

        phone_ddi: '55',
        phone_number: this.maskPipe.transform(
          '139' +
            String(fakerbr.random.number({ min: 11111111, max: 99999999 })),
          '(00) 00000-0000'
        ),

        terms: true,
      });

      if (!this.haveFund) {
        this.form.patchValue({
          fund: randomFundID,
        });
      }
    });
  }

  async getBasicInfo(token: string) {
    if (!token) return;

    try {
      const res = await this.api.get({
        route: `api/registration/invite/?token=${token}`,
      });

      if (res.data.document) {
        this.haveBasicPFInfo = res.data.document.length < 18;
        this.isPF = res.data.document.length <= 14;
      }

      if (res.data.name) {
        this.name = res.data.name;
      }

      if (res.data.fund) {
        this.haveFund = true;
        this.form.controls.fund.setValue(res.data.fund);
      }

      if (this.userRole === 'provider') {
        const roleArr = res.data.agent
          .map((item) => item.name.toLowerCase())
          .join(', ');

        const pos = roleArr.lastIndexOf(', ');
        const phrase = `${roleArr.substring(0, pos)} e${roleArr.substring(
          pos + 1
        )}`;

        this.role = phrase;

        if (res.data.agent) {
          this.haveRole = true;
          this.form.controls.agent.setValue(
            res.data.agent.map((item) => item.id)
          );
        }
      }

      if (res.data.document.length > 14) {
        this.name = res.data.name.toUpperCase();
      }
    } catch (error) {
      console.error(error);
    }
  }

  backToLogin() {
    const decodedRedirect = encodeURIComponent(this.router.url);

    if (this.route.snapshot.params.token) {
      this.router.navigateByUrl(`/login?redirect=${decodedRedirect}`);
    } else {
      this.router.navigateByUrl(`/login`);
    }
  }

  renderText() {
    if (this.userRole === 'provider') {
      if (this.isPF) {
        return `seu perfil como ${this.role} na plataforma`;
      } else {
        return `sua empresa como ${this.role} junto`;
      }
    } else {
      if (this.isPF) {
        return `seu perfil como ${
          this.userRole === 'assignor' ? 'cedente' : 'avalista'
        } na plataforma`;
      } else {
        return `sua empresa como ${
          this.userRole === 'assignor' ? 'cedente' : 'avalista'
        } na plataforma`;
      }
    }
  }

  async getAgents() {
    try {
      const res = await this.api.get({
        route: 'api/registration/simplified-agent/',
      });

      this.agentOptions = res.map((item: any) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    } catch (error) {
      console.warn(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }
  }

  async getFunds() {
    try {
      const res = await this.api.get({
        route: 'api/registration/fund',
        token: false,
      });

      const filteredArr = Utils.filterRepeatedValues(res.funds, 'id');

      const funds = filteredArr.map((fund: any) => {
        return {
          label: fund.name,
          value: fund.id,
        };
      });

      this.fundOptions = funds;
    } catch (error) {
      this.toast.show('error', 'Erro!', 'Erro ao carregar fundos de interesse');
    }

    this.loading = false;
  }

  handleKeyEnter(event: any) {
    event.preventDefault();
  }
}
