import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ToastService } from 'src/app/services/toast/toast.service';

type SettingsPermissions = {
  audit: boolean;
  zeroing_fund: boolean;
  documents: boolean;
  agents: boolean;
  group_permission: boolean;
  user_group: boolean;
  configuration: boolean;
};

@Component({
  selector: 'app-dashboard-settings',
  templateUrl: './dashboard-settings.component.html',
  styleUrls: ['./dashboard-settings.component.scss'],
})
export class DashboardSettingsComponent implements OnInit {
  canView: SettingsPermissions = {
    agents: false,
    documents: false,
    zeroing_fund: false,
    audit: false,
    group_permission: false,
    user_group: false,
    configuration: false,
  };

  departments: any[] = [];

  constructor(
    private authService: AuthService,
    private toast: ToastService,
    private router: Router,
    private api: ApiService
  ) {}

  ngOnInit(): void {
    this.setPermissions();

    if (
      !this.canView.user_group &&
      !this.canView.group_permission &&
      !this.canView.audit &&
      !this.canView.zeroing_fund &&
      !this.canView.documents &&
      !this.canView.agents &&
      !this.canView.configuration
    ) {
      this.toast.show(
        'error',
        'Aviso',
        'Você não tem permissão para acessar essa página.'
      );
      this.router.navigateByUrl('/app/dashboard');
    }

    this.getDepartments();
  }

  setPermissions() {
    const permissionArr = [
      'de_can_view_agents',
      'de_can_view_documents',
      'de_can_view_zeroing_fund',
      'de_can_view_audit',
      'de_can_view_group_permission',
      'de_can_view_user_group',
      'de_can_view_configuration',
    ];

    permissionArr.forEach((permission) => {
      const label = permission.split('_').slice(3).join('_');

      this.canView[`${label}`] = this.authService.verifyPermission([
        permission,
      ]);
    });
  }

  async getDepartments() {
    try {
      const res = await this.api.get({
        route: 'api/departments',
        token: true,
      });

      this.departments = res;
    } catch (error) {
      this.toast.show('error', 'Erro!', error.error.detail);
    }
  }
}
