import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { ApiService } from 'src/app/services/api/api.service';
import { FundService } from 'src/app/services/fund/fund.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import Utils from 'src/app/helpers/utils';

@Component({
  selector: 'app-dashboard-fund-approval',
  templateUrl: './dashboard-fund-approval.component.html',
  styleUrls: ['./dashboard-fund-approval.component.scss'],
})
export class DashboardFundApprovalComponent implements OnInit {
  reproveForm = this.formBuilder.group({
    description: [null, [Validators.required]],
  });

  tabs: Tab[] = [
    { label: 'Dados básicos', value: 'dados-basicos' },
    { label: 'Transferência', value: 'transferencias' },
    { label: 'Dados complementares', value: 'dados-complementares' },
    { label: 'Cotas', value: 'cotas' },
    { label: 'Prestadores de serviço', value: 'prestadores-servico' },
    { label: 'Taxas', value: 'taxas' },
    { label: 'Bancário', value: 'bancarios' },
    { label: 'Anexos', value: 'anexos' },
    { label: 'Histórico', value: 'historico' },
    { label: 'Minutas', value: 'modelos' },
    { label: 'Cedentes', value: 'cedentes' },
  ];

  permissionObj: PermissionValues = {
    canChange: false,
  };

  tabIndex = 0;
  activedTab = 'dados-basicos';

  fund: any = undefined;
  approval: UserApprovalResponse = undefined;

  self_view = false;

  signing = false;

  approve = false;
  warningModal = false;
  resetApproval = false;
  loading = true;

  constructor(
    private route: ActivatedRoute,
    private toast: ToastService,
    private api: ApiService,
    protected sanitizer: DomSanitizer,
    public fundService: FundService,
    public router: Router,
    private formBuilder: FormBuilder,
    private authService: AuthService
  ) {}

  ngOnDestroy(): void {
    this.fundService.reset();
  }

  ngOnInit(): void {
    this.authService.permissionsLoadedEvent.subscribe(() => {
      this.authService.canViewPage(['de_can_view_fund']);
    });

    this.route.params.subscribe((params) => {
      this.loading = true;
      this.tabIndex = history.state.step ?? 0;
      this.activedTab = this.tabs[this.tabIndex].value;
      this.getData();
      this.setPermissions();
    });

    this.tabIndex = history.state.step ?? 0;
    this.activedTab = this.tabs[this.tabIndex].value;
    this.getData();
    this.setPermissions();
  }

  async getFund() {
    try {
      const data = await this.api.get({
        route: 'api/v2/approvals/version/',
        params: {
          fund: this.route.snapshot.params.id,
          tab: '',
        },
        token: true,
      });

      const fund = { ...data };

      this.approval = fund[0];
      this.fund = fund[0].fund[0];
      //   this.fundService.signatures = fund[0].signatures;
      this.fundService.fundData = {
        ...this.fundService.fundData,
        basic: this.fund,
      };
    } catch (error) {
      this.toast.show(
        'error',
        'Erro',
        'Erro ao carregar dados básicos: ' + error
      );
    }
  }

  getChoicesName(object: string, value: any) {
    const choices = this.fundService.choices;

    const choiceObject = choices[object];

    if (choiceObject) {
      return choiceObject.find((_choice: any) => _choice.value == value);
    }

    return { label: '', value: '' };
  }

  async getChoices() {
    try {
      const res = await this.api.get({
        route: 'api/registration/new/fund-choices',
        token: true,
      });

      const choices: { [Key in any]: any } = {};

      Object.entries(res).forEach(([key, value]: any) => {
        choices[key.toLowerCase()] = value.map((item: any) => {
          return {
            label: item.label,
            value: String(item.id),
          };
        });
      });

      const filterFunctions = [4, 6, 7, 8, 9, 10];

      choices.service_provider_choices =
        choices.service_provider_choices.filter((service_provider: any) =>
          filterFunctions.includes(Number(service_provider.value))
        );

      this.fundService.choices = choices;
    } catch (error) {
      this.toast.show('error', 'Erro!', 'Erro ao carregar dados');
    }
  }

  async signApproval(approve: boolean) {
    this.signing = true;
    try {
      const payload = {
        user_approval: this.approval.id,
        department_approval: this.approval.current_department.id,
        approved: approve,
        restart: this.resetApproval,
        reason: approve ? '' : this.reproveForm.value.description,
        signed: true,
      };

      const res = await this.api.post({
        route: 'api/approvals/user_approval_signature/',
        token: true,
        body: payload,
      });

      if (approve) {
        this.toast.show(
          'info',
          'Tudo certo',
          'O fundo foi aprovado com sucesso'
        );
      } else {
        this.toast.show(
          'info',
          `Fundo ${this.resetApproval ? 'retornado' : 'reprovado'}`,
          'Uma notificação foi enviada ao responsável'
        );
      }

      this.warningModal = false;
      this.reproveForm.reset();

      if (this.resetApproval) {
        this.router.navigateByUrl('/app/dashboard');
      } else {
        await this.getData();
      }
    } catch (error) {
      this.toast.show('error', 'Erro', 'Erro ao aprovar inscrição');
      console.error(error);
    }
    this.signing = false;
  }

  async getData() {
    await Promise.all([this.getFund(), this.getChoices()]);
    this.loading = false;
  }

  onChangeTab(index: number) {
    this.activedTab = this.tabs[index].value;
    this.tabIndex = index;
  }

  async rediretToEdit() {
    this.router.navigate([`app/funds/approval/update/${this.fund.id}/`]);
  }

  closeWarningModal() {
    this.warningModal = false;
    this.reproveForm.reset();
  }

  handleApproval(approve: boolean, reset?: boolean) {
    this.approve = approve;
    this.warningModal = true;
    this.resetApproval = reset ?? false;
  }

  getInitials(name: any) {
    return Utils.getInitials(name);
  }

  setPermissions() {
    const permissions = Object.keys(this.permissionObj).map(
      (key) => `de_can_${key.split('can').join('').toLowerCase()}_fund`
    );

    permissions.forEach((permission, idx) => {
      this.permissionObj[Object.keys(this.permissionObj)[idx]] =
        this.authService.verifyPermission(permission);
    });
  }
}
