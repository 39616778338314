export const permission: Permission[] = [
  {
    id: 534,
    name: 'Pode adicionar Acesso',
    codename: 'de_can_add_access',
    content_type: 77,
  },
  {
    id: 546,
    name: 'Pode adicionar Agentes',
    codename: 'de_can_add_agents',
    content_type: 77,
  },
  {
    id: 506,
    name: 'Pode adicionar Cedentes',
    codename: 'de_can_add_assignor',
    content_type: 77,
  },
  {
    id: 558,
    name: 'Pode adicionar Audiorias',
    codename: 'de_can_add_audit',
    content_type: 77,
  },
  {
    id: 522,
    name: 'Pode adicionar Colaboradores',
    codename: 'de_can_add_collaborator',
    content_type: 77,
  },
  {
    id: 526,
    name: 'Pode adicionar Configurações',
    codename: 'de_can_add_configuration',
    content_type: 77,
  },
  {
    id: 566,
    name: 'Pode adicionar Departamentos',
    codename: 'de_can_add_department',
    content_type: 77,
  },
  {
    id: 550,
    name: 'Pode adicionar Documentos',
    codename: 'de_can_add_documents',
    content_type: 77,
  },
  {
    id: 530,
    name: 'Pode adicionar Minutas',
    codename: 'de_can_add_draft',
    content_type: 77,
  },
  {
    id: 502,
    name: 'Pode adicionar Fundos',
    codename: 'de_can_add_fund',
    content_type: 77,
  },
  {
    id: 542,
    name: 'Pode adicionar Convite',
    codename: 'de_can_add_invite',
    content_type: 77,
  },
  {
    id: 514,
    name: 'Pode adicionar Prestadores de Serviço',
    codename: 'de_can_add_provider',
    content_type: 77,
  },
  {
    id: 518,
    name: 'Pode adicionar Cadastros',
    codename: 'de_can_add_register',
    content_type: 77,
  },
  {
    id: 538,
    name: 'Pode adicionar Representantes',
    codename: 'de_can_add_representative',
    content_type: 77,
  },
  {
    id: 510,
    name: 'Pode adicionar Cotistas',
    codename: 'de_can_add_shareholder',
    content_type: 77,
  },
  {
    id: 562,
    name: 'Pode adicionar Testemunhas',
    codename: 'de_can_add_witnesses',
    content_type: 77,
  },
  {
    id: 554,
    name: 'Pode adicionar Fundos de zeragem',
    codename: 'de_can_add_zeroing_fund',
    content_type: 77,
  },
  {
    id: 536,
    name: 'Pode alterar Acesso',
    codename: 'de_can_change_access',
    content_type: 77,
  },
  {
    id: 548,
    name: 'Pode alterar Agentes',
    codename: 'de_can_change_agents',
    content_type: 77,
  },
  {
    id: 508,
    name: 'Pode alterar Cedentes',
    codename: 'de_can_change_assignor',
    content_type: 77,
  },
  {
    id: 560,
    name: 'Pode alterar Audiorias',
    codename: 'de_can_change_audit',
    content_type: 77,
  },
  {
    id: 524,
    name: 'Pode alterar Colaboradores',
    codename: 'de_can_change_collaborator',
    content_type: 77,
  },
  {
    id: 528,
    name: 'Pode alterar Configurações',
    codename: 'de_can_change_configuration',
    content_type: 77,
  },
  {
    id: 568,
    name: 'Pode alterar Departamentos',
    codename: 'de_can_change_department',
    content_type: 77,
  },
  {
    id: 552,
    name: 'Pode alterar Documentos',
    codename: 'de_can_change_documents',
    content_type: 77,
  },
  {
    id: 532,
    name: 'Pode alterar Minutas',
    codename: 'de_can_change_draft',
    content_type: 77,
  },
  {
    id: 504,
    name: 'Pode alterar Fundos',
    codename: 'de_can_change_fund',
    content_type: 77,
  },
  {
    id: 544,
    name: 'Pode alterar Convite',
    codename: 'de_can_change_invite',
    content_type: 77,
  },
  {
    id: 516,
    name: 'Pode alterar Prestadores de Serviço',
    codename: 'de_can_change_provider',
    content_type: 77,
  },
  {
    id: 520,
    name: 'Pode alterar Cadastros',
    codename: 'de_can_change_register',
    content_type: 77,
  },
  {
    id: 540,
    name: 'Pode alterar Representantes',
    codename: 'de_can_change_representative',
    content_type: 77,
  },
  {
    id: 512,
    name: 'Pode alterar Cotistas',
    codename: 'de_can_change_shareholder',
    content_type: 77,
  },
  {
    id: 564,
    name: 'Pode alterar Testemunhas',
    codename: 'de_can_change_witnesses',
    content_type: 77,
  },
  {
    id: 556,
    name: 'Pode alterar Fundos de zeragem',
    codename: 'de_can_change_zeroing_fund',
    content_type: 77,
  },
  {
    id: 537,
    name: 'Pode deletar Acesso',
    codename: 'de_can_delete_access',
    content_type: 77,
  },
  {
    id: 549,
    name: 'Pode deletar Agentes',
    codename: 'de_can_delete_agents',
    content_type: 77,
  },
  {
    id: 509,
    name: 'Pode deletar Cedentes',
    codename: 'de_can_delete_assignor',
    content_type: 77,
  },
  {
    id: 561,
    name: 'Pode deletar Audiorias',
    codename: 'de_can_delete_audit',
    content_type: 77,
  },
  {
    id: 525,
    name: 'Pode deletar Colaboradores',
    codename: 'de_can_delete_collaborator',
    content_type: 77,
  },
  {
    id: 529,
    name: 'Pode deletar Configurações',
    codename: 'de_can_delete_configuration',
    content_type: 77,
  },
  {
    id: 569,
    name: 'Pode deletar Departamentos',
    codename: 'de_can_delete_department',
    content_type: 77,
  },
  {
    id: 553,
    name: 'Pode deletar Documentos',
    codename: 'de_can_delete_documents',
    content_type: 77,
  },
  {
    id: 533,
    name: 'Pode deletar Minutas',
    codename: 'de_can_delete_draft',
    content_type: 77,
  },
  {
    id: 505,
    name: 'Pode deletar Fundos',
    codename: 'de_can_delete_fund',
    content_type: 77,
  },
  {
    id: 545,
    name: 'Pode deletar Convite',
    codename: 'de_can_delete_invite',
    content_type: 77,
  },
  {
    id: 517,
    name: 'Pode deletar Prestadores de Serviço',
    codename: 'de_can_delete_provider',
    content_type: 77,
  },
  {
    id: 521,
    name: 'Pode deletar Cadastros',
    codename: 'de_can_delete_register',
    content_type: 77,
  },
  {
    id: 541,
    name: 'Pode deletar Representantes',
    codename: 'de_can_delete_representative',
    content_type: 77,
  },
  {
    id: 513,
    name: 'Pode deletar Cotistas',
    codename: 'de_can_delete_shareholder',
    content_type: 77,
  },
  {
    id: 565,
    name: 'Pode deletar Testemunhas',
    codename: 'de_can_delete_witnesses',
    content_type: 77,
  },
  {
    id: 557,
    name: 'Pode deletar Fundos de zeragem',
    codename: 'de_can_delete_zeroing_fund',
    content_type: 77,
  },
  {
    id: 5355,
    name: 'Pode visualizar Minha Área',
    codename: 'de_can_view_my_area',
    content_type: 77,
  },
  {
    id: 5355,
    name: 'Pode visualizar Meus Registros',
    codename: 'de_can_view_all_registers',
    content_type: 77,
  },
  {
    id: 535,
    name: 'Pode visualizar Acesso',
    codename: 'de_can_view_access',
    content_type: 77,
  },
  {
    id: 547,
    name: 'Pode visualizar Agentes',
    codename: 'de_can_view_agents',
    content_type: 77,
  },
  {
    id: 507,
    name: 'Pode visualizar Cedentes',
    codename: 'de_can_view_assignor',
    content_type: 77,
  },
  {
    id: 559,
    name: 'Pode visualizar Audiorias',
    codename: 'de_can_view_audit',
    content_type: 77,
  },
  {
    id: 523,
    name: 'Pode visualizar Colaboradores',
    codename: 'de_can_view_collaborator',
    content_type: 77,
  },
  {
    id: 527,
    name: 'Pode visualizar Configurações',
    codename: 'de_can_view_configuration',
    content_type: 77,
  },
  {
    id: 567,
    name: 'Pode visualizar Departamentos',
    codename: 'de_can_view_department',
    content_type: 77,
  },
  {
    id: 551,
    name: 'Pode visualizar Documentos',
    codename: 'de_can_view_documents',
    content_type: 77,
  },
  {
    id: 531,
    name: 'Pode visualizar Minutas',
    codename: 'de_can_view_draft',
    content_type: 77,
  },
  {
    id: 503,
    name: 'Pode visualizar Fundos',
    codename: 'de_can_view_fund',
    content_type: 77,
  },
  {
    id: 543,
    name: 'Pode visualizar Convite',
    codename: 'de_can_view_invite',
    content_type: 77,
  },
  {
    id: 515,
    name: 'Pode visualizar Prestadores de Serviço',
    codename: 'de_can_view_provider',
    content_type: 77,
  },
  {
    id: 519,
    name: 'Pode visualizar Cadastros',
    codename: 'de_can_view_register',
    content_type: 77,
  },
  {
    id: 539,
    name: 'Pode visualizar Representantes',
    codename: 'de_can_view_representative',
    content_type: 77,
  },
  {
    id: 511,
    name: 'Pode visualizar Cotistas',
    codename: 'de_can_view_shareholder',
    content_type: 77,
  },
  {
    id: 563,
    name: 'Pode visualizar Testemunhas',
    codename: 'de_can_view_witnesses',
    content_type: 77,
  },
  {
    id: 555,
    name: 'Pode visualizar Fundos de zeragem',
    codename: 'de_can_view_zeroing_fund',
    content_type: 77,
  },
  {
    id: 1000,
    name: 'Pode visualizar grupos de permissão',
    codename: 'de_can_view_group_permission',
    content_type: 77,
  },
  {
    id: 1001,
    name: 'Pode adicionar grupos de permissão',
    codename: 'de_can_add_group_permission',
    content_type: 77,
  },
  {
    id: 1002,
    name: 'Pode editar grupos de permissão',
    codename: 'de_can_change_group_permission',
    content_type: 77,
  },
  {
    id: 1003,
    name: 'Pode deletar grupos de permissão',
    codename: 'de_can_delete_group_permission',
    content_type: 77,
  },
  {
    id: 1004,
    name: 'Pode visualizar grupos de usuários',
    codename: 'de_can_view_user_group',
    content_type: 77,
  },
  {
    id: 1005,
    name: 'Pode adicionar grupos de usuários',
    codename: 'de_can_add_user_group',
    content_type: 77,
  },
  {
    id: 1006,
    name: 'Pode editar grupos de usuários',
    codename: 'de_can_change_user_group',
    content_type: 77,
  },
  {
    id: 1007,
    name: 'Pode editar usuários',
    codename: 'de_can_change_user_group_user',
    content_type: 77,
  },
  {
    id: 1008,
    name: 'Pode deletar usuários',
    codename: 'de_can_delete_user_group_user',
    content_type: 77,
  },

  {
    id: 1009,
    name: 'Pode visualizar empresa',
    codename: 'de_can_view_user_group_company',
    content_type: 77,
  },
  {
    id: 10010,
    name: 'Pode adicionar empresa',
    codename: 'de_can_add_user_group_company',
    content_type: 77,
  },
  {
    id: 10011,
    name: 'Pode editar empresa',
    codename: 'de_can_change_user_group_company',
    content_type: 77,
  },
  {
    id: 10012,
    name: 'Pode deletar empresa',
    codename: 'de_can_delete_user_group_company',
    content_type: 77,
  },

  {
    id: 10013,
    name: 'Pode visualizar histórico',
    codename: 'de_can_view_permission_history',
    content_type: 77,
  },
  {
    id: 10014,
    name: 'Pode adicionar histórico',
    codename: 'de_can_add_permission_history',
    content_type: 77,
  },
  {
    id: 10015,
    name: 'Pode editar histórico',
    codename: 'de_can_change_permission_history',
    content_type: 77,
  },
  {
    id: 10016,
    name: 'Pode deletar histórico',
    codename: 'de_can_delete_permission_history',
    content_type: 77,
  },
  {
    codename: 'de_can_delete_user_group',
    id: 10017,
    name: 'teste',
    content_type: 77,
  },
  {
    codename: 'de_can_add_user_group',
    id: 10018,
    name: 'teste',
    content_type: 77,
  },
  {
    codename: 'de_can_view_user_group',
    id: 10019,
    name: 'teste',
    content_type: 77,
  },
  {
    codename: 'de_can_change_user_group',
    id: 10020,
    name: 'teste',
    content_type: 77,
  },
  {
    codename: 'de_can_add_permission_history',
    id: 10021,
    name: 'teste',
    content_type: 77,
  },
  {
    codename: 'de_can_delete_permission_history',
    id: 10022,
    name: 'teste',
    content_type: 77,
  },
  {
    codename: 'de_can_change_permission_history',
    id: 10023,
    name: 'teste',
    content_type: 77,
  },
  {
    codename: 'de_can_view_permission_history',
    id: 10024,
    name: 'teste',
    content_type: 77,
  },
  {
    codename: 'de_can_change_user_group_user',
    id: 10025,
    name: 'teste',
    content_type: 77,
  },
  {
    codename: 'de_can_add_user_group_user',
    id: 10026,
    name: 'teste',
    content_type: 77,
  },
  {
    codename: 'de_can_view_user_group_user',
    id: 10027,
    name: 'teste',
    content_type: 77,
  },
  {
    codename: 'de_can_delete_user_group_user',
    id: 10028,
    name: 'teste',
    content_type: 77,
  },
];
