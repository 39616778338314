import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dashboard-departments',
  templateUrl: './dashboard-departments.component.html',
  styleUrls: ['./dashboard-departments.component.scss'],
})
export class DashboardDepartmentsComponent implements OnInit {
  departmentForm = this.formBuilder.group({
    name: [null, [Validators.required]],
  });

  permissionObj: PermissionValues = {
    canAdd: false,
    canChange: false,
    canDelete: false,
  };

  modal = false;

  departments: any[] = [];
  department: any = undefined;

  filteredData = this.departments;

  departmentLinks = false;

  perPage = 50;
  currentPage = 1;
  pages = [1];

  loading = true;

  constructor(
    private formBuilder: FormBuilder,
    private api: ApiService,
    private toast: ToastService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.authService.permissionsLoadedEvent.subscribe(() => {
      this.authService.canViewPage(['de_can_view_department']);
    });

    this.getDepartments();
    this.setPermissions();
  }

  async getDepartments() {
    try {
      const res = await this.api.get({
        route: 'api/departments',
        token: true,
      });

      this.departments = res;
      this.loading = false;
    } catch (error) {
      this.toast.show('error', 'Erro!', 'Erro ao recuperar departamentos');
    }
  }

  setDepartment(department: any) {
    this.departmentForm.patchValue({
      ...department,
    });
    this.department = department;
    this.formToggle(true);
  }

  async deleteDepartments(id: number) {
    const { isConfirmed } = await Swal.fire({
      title: 'Tem certeza?',
      text: 'Você não poderá reverter isso!',
      icon: 'warning',
      showConfirmButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sim, apague!',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
    });

    if (isConfirmed) {
      try {
        this.api.delete({
          route: 'api/departments',
          token: true,
          params: {
            id,
            // role,
          },
        });

        this.departments = this.departments.filter(
          (department) => department.id !== id
        );

        Swal.fire({
          title: 'Apagado!',
          text: 'Departamento apagado com sucesso',
          icon: 'success',
          confirmButtonColor: '#3085d6',
        });
      } catch (error) {
        Swal.fire({
          title: 'Erro!',
          text: 'Erro ao apagar departamento',
          icon: 'error',
          confirmButtonColor: '#3085d6',
        });
      }
    }
  }

  paginateData() {
    const filtered = this.departments.filter(
      (_, i) =>
        i >= this.perPage * (this.currentPage - 1) &&
        i < this.perPage * this.currentPage
    );

    const pageNumber = Math.ceil(this.departments.length / this.perPage);

    const pages = [];

    for (let i = 1; i <= pageNumber; i++) {
      pages.push(i);
    }

    this.pages = pages;
    this.filteredData = filtered;
  }

  formToggle(status: boolean) {
    this.modal = status;
  }

  closeModal() {
    this.modal = false;
    this.department = undefined;
    this.departmentLinks = false;
    this.departmentForm.reset();
  }

  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;

      this.paginateData();
    }
  }

  getFundInitials(item: any) {
    const name = item.first_name + ' ' + item.last_name;
    const nameArray = name.split(' ');
    const initials = nameArray.map((word: string) => word.charAt(0)).join('');
    return `${initials[0].toUpperCase()}${
      initials[1] ? initials[1].toUpperCase() : ''
    }`;
  }

  showLinks(department: any) {
    this.departmentLinks = true;
    this.department = department;
  }

  nextPage() {
    if (this.currentPage < this.pages.length) {
      this.currentPage++;

      this.paginateData();
    }
  }

  changePage(page: number) {
    this.currentPage = page;

    this.paginateData();
  }

  async addDepartments() {
    try {
      const values = this.departmentForm.value;

      const alreadyHaveDepartment = this.departments.find(
        (item) => item.name === values.name
      );

      if (alreadyHaveDepartment) {
        this.toast.show('error', 'Erro!', 'Departamento já existe');
        return;
      }

      const res = this.department
        ? await this.api.put({
            route: 'api/departments',
            token: true,
            body: values,
            params: { id: this.department.id },
          })
        : await this.api.post({
            route: 'api/departments',
            token: true,
            body: values,
          });

      this.toast.show('info', 'Sucesso!', 'Departamento adicionado');
      this.getDepartments();
      this.formToggle(false);
    } catch (error) {
      this.toast.show('error', 'Erro!', 'Erro ao adicionar departamento');
    }
  }

  setPermissions() {
    const permissions = Object.keys(this.permissionObj).map(
      (key) => `de_can_${key.split('can').join('').toLowerCase()}_department`
    );

    permissions.forEach((permission, idx) => {
      this.permissionObj[Object.keys(this.permissionObj)[idx]] =
        this.authService.verifyPermission(permission);
    });
  }
}
