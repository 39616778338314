import { Component, OnInit } from '@angular/core';
import { SignupService } from 'src/app/services/signup/signup.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { MaskPipe } from 'ngx-mask';
import { FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-dashboard-users-group-details',
  templateUrl: './dashboard-users-group-details.component.html',
  styleUrls: ['./dashboard-users-group-details.component.scss'],
})
export class DashboardUsersGroupDetailsComponent implements OnInit {
  filterForm = this.formBuilder.group({
    company: [null, []],
    document: [null, []],
    company_status: [null, []],
  });

  statusOptions: SelectItem[] = [
    { label: 'Ativo', value: 'true' },
    { label: 'Inativo', value: 'false' },
  ];

  data: any[] = [];
  loading = true;
  hasFilters = false;
  showFilterModal = false;

  constructor(
    private toast: ToastService,
    public signupService: SignupService,
    private router: Router,
    private api: ApiService,
    private route: ActivatedRoute,
    private maskPipe: MaskPipe,
    private formBuilder: FormBuilder,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.authService.permissionsLoadedEvent.subscribe(() => {
      this.authService.canViewPage(['de_can_view_user_group_company']);
    });

    this.getData();

    this.filterForm.valueChanges.subscribe((values) => {
      this.hasFilters = Object.values(values).some(
        (value) => value !== null && value !== ''
      );
    });
  }

  async getData() {
    try {
      const filters = {};

      if (this.hasFilters) {
        const values = this.filterForm.value;
        Object.entries(values).forEach(([key, value]: any) => {
          if (value !== null && value !== '') {
            filters[key] = value;
          }
        });
      }

      if (filters['start_date'] && !filters['end_date']) {
        const atualDate = new Date().toISOString().split('T')[0];
        filters['end_date'] = atualDate;
      }

      if (filters['end_date'] && !filters['start_date']) {
        filters['start_date'] = '2000-01-01';
      }

      const id = this.route.snapshot.params.id;
      const res = await this.api.get({
        route: `api/v2/user_group/${id}/`,
        token: true,
        params: {
          type: 'view',
          ...filters,
        },
      });

      this.data = res;
    } catch (error) {
      console.error(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }
    this.loading = false;
  }

  resetForm() {
    this.hasFilters = false;
    this.filterForm.reset();
    this.showFilterModal = false;
    this.loading = true;
    this.getData();
  }

  redirectToDetails(id: any) {
    this.router.navigateByUrl(
      `/app/settings/permissions/company/details/${id}`
    );
  }

  formatDocument(item: string) {
    return item.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
      '$1.$2.$3/$4-$5'
    );
  }

  formatPhone(item: { ddi: string; number: string }) {
    const ddiCode = item.ddi ? `+${item.ddi} ` : '';
    const checkNumberLength = item.number.length === 9;
    const formattedNumber = this.maskPipe.transform(
      item.number,
      checkNumberLength ? '00000-0000' : '0000-0000'
    );

    return `${ddiCode}${formattedNumber}`;
  }

  async filterData() {
    this.loading = true;

    try {
      const values = this.filterForm.value;

      const filters = {};

      Object.entries(values).forEach(([key, value]: any) => {
        if (value !== null && value !== '') {
          let newValue = value;
          if (key === 'start_date' || key === 'end_date') {
            newValue = value.split('/').reverse().join('-');
          }
          filters[key] = newValue;
        }
      });

      if (filters['start_date'] && !filters['end_date']) {
        const atualDate = new Date().toISOString().split('T')[0];
        filters['end_date'] = atualDate;
      }

      if (filters['end_date'] && !filters['start_date']) {
        filters['start_date'] = '2000-01-01';
      }

      const id = this.route.snapshot.params.id;
      const res = await this.api.get({
        route: `api/v2/user_group/${id}/`,
        token: true,
        params: {
          type: 'view',
          ...filters,
        },
      });

      this.data = res;
      this.hasFilters = true;
      this.showFilterModal = false;
    } catch (error) {
      console.error(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }
    this.loading = false;
  }
}
