import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import Utils from 'src/app/helpers/utils';
import { ApiService } from 'src/app/services/api/api.service';
import { FundService } from 'src/app/services/fund/fund.service';
import { SignupService } from 'src/app/services/signup/signup.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import fakerbr from 'faker-br';
import { MaskPipe } from 'ngx-mask';
import { AuthService } from 'src/app/services/auth/auth.service';
import { distinctUntilChanged } from 'rxjs/operators';

const filter_dict = {
  search: 'search',
  roles: 'register__role__id',
  created_from: 'created_at__gte',
  created_to: 'created_at__lte',
  updated_from: 'updated_at__gte',
  updated_to: 'updated_at__lte',
};

@Component({
  selector: 'app-dashboard-admin-assignors',
  templateUrl: './dashboard-admin-assignors.component.html',
  styleUrls: ['./dashboard-admin-assignors.component.scss'],
})
export class DashboardAdminAssignorsComponent implements OnInit {
  errrorMessageObj: ErrorMessagesObj = {
    created_from: '',
    created_to: '',
    updated_from: '',
    updated_to: '',
  };

  form = this.formBuilder.group({
    name: [null, []],
    agent: [null, []],
    document: [null, [Validators.required, Utils.validateCpfCnpj]],
    due_date: [null, [Validators.required, Utils.validateMinDate]],
    email: [null, [Validators.required, Validators.email]],
    fund: [null, []],
    is_approver: [false, []],
  });

  filterForm = this.formBuilder.group({
    search: [null],
    agents: [null],
    roles: [null],
    created_from: [
      null,
      [
        (control: FormControl) => {
          if (!control.value) {
            return null;
          }

          if (Utils.validateDateValid(control)) {
            this.errrorMessageObj.created_from = 'Data inválida';
            return { date: true };
          }

          control.parent?.controls?.['created_to'].setValidators([
            Validators.required,
            (control: FormControl) => {
              if (Utils.validateDateValid(control)) {
                this.errrorMessageObj.created_to = 'Data inválida';
                return { date: true };
              }

              if (Utils.validateMaxMinToday(control)) {
                this.errrorMessageObj.created_to =
                  'A data não pode ser maior que hoje';
                return { max: true };
              }

              const toValue = control.value.split('/').reverse().join('-');
              const fromValue = control.parent.controls?.['created_from'].value
                ? control.parent.controls?.['created_from'].value
                    .split('/')
                    .reverse()
                    .join('-')
                : null;

              if (fromValue) {
                let fromDate = new Date(fromValue);
                const toDate = new Date(toValue);

                if (fromDate > toDate) {
                  this.errrorMessageObj.created_to =
                    'A data final não pode ser menor que a data inicial';
                  return { min: true };
                }
              }

              return null;
            },
          ]);

          control.parent?.controls?.['created_to'].updateValueAndValidity();

          if (Utils.validateMinDate(control)) {
            this.errrorMessageObj.created_from = 'Data mínima inválida';
            return { min: true };
          }

          if (Utils.validateMaxMinToday(control)) {
            this.errrorMessageObj.created_from =
              'A data não pode ser maior que hoje';
            return { max: true };
          }

          return null;
        },
      ],
    ],

    created_to: [
      null,
      [
        (control: FormControl) => {
          if (!control.value) {
            return null;
          }

          if (Utils.validateDateValid(control)) {
            this.errrorMessageObj.created_to = 'Data inválida';
            return { date: true };
          }

          if (Utils.validateMaxMinToday(control)) {
            this.errrorMessageObj.created_to =
              'A data não pode ser maior que hoje';
            return { max: true };
          }

          const toValue = control.value.split('/').reverse().join('-');
          const fromValue = control.parent.controls?.['created_from'].value
            ? control.parent.controls?.['created_from'].value
                .split('/')
                .reverse()
                .join('-')
            : null;

          if (fromValue) {
            let fromDate = new Date(fromValue);
            const toDate = new Date(toValue);

            if (fromDate > toDate) {
              this.errrorMessageObj.created_to =
                'A data final não pode ser menor que a data inicial';
              return { min: true };
            }
          }

          return null;
        },
      ],
    ],
    updated_from: [null],
    updated_to: [null],
  });

  assignors: any[] = [];

  agentOptions: SelectItem[] = [{ label: 'Todos', value: null }];

  roleOptions: SelectItem[] = [{ label: 'Todos', value: null }];

  filteredAssignors: any[] = this.assignors;

  loading = true;
  loadingMenu = true;
  canViewUsers = false;
  canInvite = false;

  fundOptions: SelectItem[] = [];

  newInvite = false;

  showOptions = false;

  role = '';

  selectedRole: string | null = null;
  showFilterModal = false;

  today = Utils.todayString();
  is_approver: boolean = false;
  pages = [];
  _page = 1;
  filtered = false;

  filters: number = 0;

  set page(value: number) {
    this._page = value;
    this.getUsers();
  }

  get page() {
    return this._page;
  }

  names: { [Key: string]: string } = {
    ALL: 'Todos',
    ADM: 'Administração',
    ASSIGNOR: 'Cedente',
    SHAREHOLDER: 'Cotista',
    PROVIDER: 'Prestador de serviço',
    GUARANTOR: 'Avalista',
    REPRESENTATIVE: 'Representante',
  };

  statistics = { homologated: 0, in_progress: 0, pending: 0 };

  paginate = {
    current_page: 0,
    max_pages: 0,
    per_page: 0,
    total: 0,
    viewing: 0,
  };

  constructor(
    private api: ApiService,
    private toast: ToastService,
    private route: Router,
    private formBuilder: FormBuilder,
    private fundsService: FundService,
    private signupService: SignupService,
    private authService: AuthService,
    private maskPipe: MaskPipe
  ) {}

  ngOnInit(): void {
    this.authService.menuLoadedEvent.subscribe(() => {
      if (!this.authService.verifyPermission(['de_can_view_my_area'])) {
        this.redirectToFirstMenuItem();
      } else {
        this.loadingMenu = false;
      }
    });

    if (this.authService.user.active_register) {
      this.is_approver =
        this.authService.user.active_register.register.is_approver;
      this.role =
        this.authService.user.active_register.register.role.applicable;
    }

    this.getData();
    this.setData();
    this.setFunds();
    this.setAgents();
    this.getRoles();

    this.canViewUsers = this.authService.verifyPermission(
      'de_can_view_register'
    );
    this.canInvite = this.authService.verifyPermission('de_can_add_invite');

    this.form.controls.document.valueChanges
      .pipe(distinctUntilChanged((a: any, b: any) => a === b))
      .subscribe((values) => {
        if (values) {
          this.form.controls.document.setValidators([Utils.validateCpfCnpj]);
        } else {
          this.form.controls.document.setValidators([]);
        }

        this.form.controls.document.updateValueAndValidity();
      });
  }

  async setAgents() {
    try {
      const res = await this.api.get({
        route: 'api/registration/agent/',
        token: true,
      });

      this.agentOptions = res.map((item: any) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    } catch (error) {
      console.warn(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }
  }

  resetForm() {
    this.filterForm.reset();
    this.filterForm.controls.updated_from.setValidators([]);
    this.filterForm.controls.updated_to.setValidators([]);
    this.filterForm.controls.created_from.setValidators([]);
    this.filterForm.controls.created_to.setValidators([]);

    this.filterForm.controls.updated_from.updateValueAndValidity();
    this.filterForm.controls.updated_to.updateValueAndValidity();
    this.filterForm.controls.created_from.updateValueAndValidity();
    this.filterForm.controls.created_to.updateValueAndValidity();

    this.filters = 0;

    // this.showFilterModal = false;
  }

  async getRoles() {
    try {
      const res = await this.api.get({
        route: 'api/roles',
        token: true,
      });

      const newAgentsOptions = res.map((_agent: any) => {
        const roles = _agent.slug.split('-');

        if (roles[0].includes('ADM') || roles[0].includes('REPRESENTATIVE')) {
          return undefined;
        }
        return {
          label: `${this.names[roles[0]]} ${_agent.name}`,
          value: _agent.id,
          extra: roles.includes('PJ') ? 'PJ' : 'PF',
        };
      });

      this.roleOptions = newAgentsOptions.filter(
        (agent: any) => agent !== undefined
      );
    } catch (error) {
      console.warn(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }
  }

  async filterUsers() {
    this.filters = 0;

    try {
      const values = this.filterForm.value;

      this.filtered = Object.entries(values).some(([key, value]: any) => {
        return value !== null && value !== '';
      });

      const filters = {};

      Object.entries(values).forEach(([key, value]: any) => {
        if (value !== null && value !== '') {
          filters[key] = value;
        }
      });

      const { data } = await this.api.get<ApiResponse<UserApprovalResponse[]>>({
        route: 'api/approvals/user_approval/',
        token: true,
        params: filters,
      });

      this.setListData(data);

      this.showFilterModal = false;
    } catch (error) {
      console.error(error);
      this.toast.show(
        'error',
        'Erro',
        'Ocorreu um erro ao carregar os dados de cedente.'
      );
    }
  }

  setFunds() {
    this.fundsService.onChange.subscribe(() => {
      this.fundOptions = this.fundsService.fundList;
    });
  }

  closeModal() {
    this.selectedRole = null;
    this.newInvite = false;
    this.showFilterModal = false;
    this.form.reset();
  }

  async sendInvite() {
    try {
      /* const { exists: emailExists } = await this.api.post({
        route: `validate-email-exists/`,
        body: { email: this.form.value.email },
      });

      if (emailExists) {
        this.toast.show(
          'error',
          'Erro',
          'Já existe um usuário com esse e-mail, não é necessário enviar um convite.'
        );
        return;
      }

      if (this.form.value.document) {
        const { exists: documentExists } = await this.api.post({
          route: `validate-document-exists/`,
          body: { document: this.form.value.document },
        });

        if (documentExists) {
          this.toast.show(
            'error',
            'Erro',
            'Já existe um usuário com esse documento, não é necessário enviar um convite.'
          );
          return;
        }
      } */

      await this.api.post({
        route: 'api/registration/invite/',
        token: true,
        body: {
          data: {
            ...this.form.value,
            register: this.authService.user.active_register.register.id,
          },
          type: this.selectedRole,
        },
      });

      this.toast.show(
        'info',
        'Sucesso',
        `${this.formatName(this.selectedRole)} convidado com sucesso`
      );
      this.closeModal();
    } catch (error) {
      console.error(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao enviar o convite');
    }
  }

  getDisabled() {
    return this.form.invalid;
  }

  async getData() {
    await Promise.all([this.getUsers()]);
  }

  toggleShowOptions() {
    this.showOptions = !this.showOptions;
  }

  setListData(data) {
    const register = this.authService.user.active_register
      ? this.authService.user.active_register.register
      : this.authService.user;

    const pending = data.filter((item) => {
      if (item.current_department) {
        if (
          item.current_department.type === 'department' &&
          register.department &&
          item.current_department.department.id === register.department.id
        ) {
          return true;
        }

        if (
          item.current_department.type === 'agent' &&
          register.agent.length > 0
        ) {
          return register.agent.some(
            (_agent) => item.current_department.agent.id === _agent.id
          );
        }
      }

      return false;
    });

    const filteredData = pending
      .filter((assignor) => {
        if (assignor.register) {
          return !assignor.register.is_simplified;
        }
        return true;
      })
      .filter((assignor) => !(assignor.completed && !assignor.is_approved))
      .filter((assignor) => assignor.can_sign);

    this.statistics = {
      in_progress: data.filter((item) => item.step !== 0).length,
      pending: filteredData.length,
      homologated: data.filter((item) => item.completed).length,
    };

    this.assignors = filteredData;
    this.loading = false;
  }

  async getUsers(data = null) {
    try {
      const { data } = await this.api.get<ApiResponse<UserApprovalResponse[]>>({
        route: 'api/approvals/user_approval/',
        token: true,
        params: {
          my_area: true,
        },
      });

      const rolesToFilter = [];
      const roles = [
        'assignor',
        'shareholder',
        'provider',
        'representative',
        'fund',
      ];

      roles.forEach((role) => {
        const slug = `de_can_view_${role}`;
        if (!this.authService.verifyPermission(slug)) {
          rolesToFilter.push(role);
        }
      });

      const filteredData = data.filter((item) => {
        return item.register
          ? !rolesToFilter.includes(item.register.role.applicable)
          : !rolesToFilter.includes(item.type);
      });

      this.setListData(filteredData);
    } catch (error) {
      console.error(error);
      this.toast.show(
        'error',
        'Erro',
        'Ocorreu um erro ao carregar os dados de cedente.'
      );
    }
  }

  toggleFilterModal() {
    this.showFilterModal = !this.showFilterModal;
  }

  getInitials(item: UserApprovalResponse) {
    let name = '';

    if (item.register) {
      if (item.register.type.toUpperCase() === 'PF') {
        name = item.register.person.full_name;
      }

      if (item.register.type.toUpperCase() === 'PJ') {
        name = item.register.company.corporate_name;
      }
    }

    if (item.fund) {
      name = item.fund.fantasy_name;
    }

    return Utils.getInitials(name);
  }

  getDisplayName(item: UserApprovalResponse) {
    if (item.register) {
      if (item.register.type.toUpperCase() === 'PF') {
        return item.register.person.full_name;
      }

      if (item.register.type.toUpperCase() === 'PJ') {
        return item.register.company.corporate_name;
      }
    }

    if (item.fund) {
      return item.fund.fantasy_name;
    }

    return '';
  }

  getStatus(item: UserApprovalResponse) {
    const register = item.register ?? item.fund;

    if (register.approval[0].completed) {
      if (register.approval[0].is_approved) {
        return 'Aprovado internamente';
      } else {
        return `${register ? 'Cadastro' : 'Fundo'} rejeitado`;
      }
    } else {
      if (register.approval[0].current_department !== null) {
        return register.approval[0].current_department.type === 'department'
          ? register.approval[0].current_department.department.name
          : register.approval[0].current_department.agent.name;
      } else if (register.approval[0].step === 0) {
        return 'Edição';
      } else {
        return 'Departamento não definido';
      }
    }
  }

  getStatusInfo(item: UserApprovalResponse) {
    const register = item.register ?? item.fund;

    if (register.approval[0].completed) {
      if (register.approval[0].is_approved) {
        return 'Integração ao fundo';
      } else {
        if (register.approval[0].current_department !== null) {
          return register.approval[0].current_department.type === 'department'
            ? register.approval[0].current_department.department.name
            : register.approval[0].current_department.agent.name;
        } else if (register.approval[0].step === 0) {
          return 'Edição';
        } else {
          return 'Departamento não definido';
        }
      }
    } else if (register.approval[0].step === 0) {
      return 'Aguardando representantes';
    } else {
      return `Aguardando aprovação (${register.approval[0].step}/${register.approval[0].queue.departments.length})`;
    }
  }

  formatName(name: string) {
    switch (name) {
      case 'assignor':
        return 'Cedente';
      case 'shareholder':
        return 'Cotista';
      case 'provider':
        return 'Prestador de serviço';

      default:
        return 'Cedente';
    }
  }

  formatItemName(item: UserApprovalResponse) {
    if (item.register) {
      const name = item.register.role.applicable.toUpperCase();

      switch (name) {
        case 'ASSIGNOR':
          return 'Cedente';
        case 'SHAREHOLDER':
          return 'Cotista';
        case 'PROVIDER':
          let providerLabel =
            item.register.agent.length > 0
              ? item.register.agent.map((_) => _.name).join(', ')
              : 'Prestador de serviço';
          return providerLabel;

        default:
          return 'Cedente';
      }
    }

    if (item.fund) {
      return 'Fundo';
    }

    return 'Não identificado';
  }

  redirectTo(path: string) {
    this.route.navigateByUrl(path);
  }

  setData() {
    this.signupService.fillFormEvent.subscribe(() => {
      this.form.patchValue({
        name: fakerbr.name.findName(),
        document: this.maskPipe.transform(
          fakerbr.br.cnpj(),
          '00.000.000/0000-00'
        ),
        email: fakerbr.internet.email(),
        fund: fakerbr.random.number({ min: 1, max: 3 }),
      });

      if (this.selectedRole === 'provider') {
        this.form.patchValue({
          agent: [1],
        });
      }
    });
  }

  handleNewInvite(role: string) {
    this.selectedRole = role;
    this.newInvite = true;
    this.showOptions = false;

    if (role === 'provider') {
      this.form.controls.agent.setValidators([Validators.required]);
      this.form.controls.fund.setValidators([]);
    } else {
      this.form.controls.agent.setValidators([]);
      this.form.controls.fund.setValidators([Validators.required]);
    }

    this.form.controls.agent.updateValueAndValidity();
    this.form.controls.fund.updateValueAndValidity();
  }

  renderInviteTitle() {
    const role = this.formatName(this.selectedRole);
    return `Convidar ${role.toLowerCase()}`;
  }

  renderLink(item: UserApprovalResponse) {
    if (item.register) {
      return [
        `/app/details/${item.register.role.applicable}/`,
        item.register.id,
      ];
    }

    if (item.fund) {
      return [`/app/funds/approval/`, item.fund.id];
    }

    return '';
  }

  nextPage() {
    if (this.paginate.current_page < this.paginate.max_pages) {
      this.page++;
    }
  }

  prevPage() {
    if (this.paginate.current_page > 1) {
      this.page--;
    }
  }

  changePage(page: number) {
    this.page = page;
  }

  redirectToFirstMenuItem() {
    const menuItems = this.authService.navigationPermissions;

    if (this.route.url.includes('app')) {
      if (menuItems && menuItems.length > 0) {
        this.route.navigateByUrl(menuItems[0].route);
        return;
      }

      this.route.navigateByUrl('/app');
    }
  }
}
